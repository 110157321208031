import React, { memo } from "react"

import { Grid, Typography, TextField, Checkbox, styled } from "@mui/material"

import {
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import {
  AreaType,
  MeetingRoomType,
  SeatType,
} from "../../../../models/company/useLayout/type"
import { numberCheck } from "../../../../utils/numberCheck"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
    fontSize: "12px",
  },
})

export const CustomTypography = styled(Typography)({
  fontWeight: "bold",
  fontSize: "12px",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

interface Props {
  setMeetingRoom: React.Dispatch<React.SetStateAction<MeetingRoomType>>
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
  setArea: React.Dispatch<React.SetStateAction<AreaType>>
  setAreas: React.Dispatch<React.SetStateAction<AreaType[]>>
  setAreaToSeats: React.Dispatch<React.SetStateAction<SeatType[]>>
  setMeetingRooms: React.Dispatch<React.SetStateAction<MeetingRoomType[]>>
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
}

/*
  レイアウトのサイドバーに座席一覧を表示
*/
export const CompanyLayoutMeetingRoomList: React.FC<Props> = memo(
  ({
    setMeetingRoom,
    setSidebarTabType,
    meetingRooms,
    areas,
    setAreas,
    setArea,
    setAreaToSeats,
    setMeetingRooms,
    setSidebarLayoutComponent,
  }: Props) => {
    return (
      <>
        <p
          style={{
            borderBottom: "1px solid #F1F1FF",
          }}
        ></p>
        {meetingRooms.map((meetingRoom, id) => {
          return (
            <Grid
              key={id}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              container
              spacing={1}
            >
              <Grid item xs={12} sm={1.5}>
                <Checkbox
                  checked={meetingRoom.isCheck}
                  onChange={() => {
                    setMeetingRooms(() => {
                      return meetingRooms.map((oldMeetingRoom) => {
                        if (meetingRoom.id === oldMeetingRoom.id) {
                          return {
                            ...meetingRoom,
                            isCheck: !oldMeetingRoom.isCheck,
                          }
                        }
                        return oldMeetingRoom
                      })
                    })
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <CustomTextField
                  id="meeting_room_code"
                  name="meeting_room_code"
                  required
                  variant="outlined"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 3 }}
                  value={meetingRoom.meeting_room_code}
                  onChange={(e) => {
                    setMeetingRooms(() => {
                      return meetingRooms.map((oldMeetingRoom) => {
                        if (meetingRoom.id === oldMeetingRoom.id) {
                          return {
                            ...meetingRoom,
                            meeting_room_code: numberCheck(
                              Number(e.target.value)
                            )
                              ? Number(e.target.value)
                              : meetingRoom.meeting_room_code,
                          }
                        }
                        return oldMeetingRoom
                      })
                    })
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4.5}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                {meetingRoom.meeting_room_name}
              </Grid>
              <Grid item xs={12} sm={2} sx={{ fontSize: "12px" }}>
                {meetingRoom.capacity}名
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <CustomTypography
                  variant="caption"
                  color="#22BA9D"
                  onClick={() => {
                    setSidebarLayoutComponent("meetingRoomForm")
                    setSidebarTabType("meetingRoomForm")
                    setMeetingRoom(meetingRoom)
                  }}
                >
                  編集
                </CustomTypography>
              </Grid>
            </Grid>
          )
        })}
        {areas.map((area, id) => {
          {
            return (
              area.area_to_meeting_room && (
                <Grid
                  key={id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                  container
                  spacing={1}
                >
                  <Grid item xs={12} sm={1.5}>
                    <Checkbox
                      checked={area.isCheck}
                      onChange={() => {
                        setAreas(() => {
                          return areas.map((oldArea) => {
                            if (area.id === oldArea.id) {
                              return { ...area, isCheck: !oldArea.isCheck }
                            }
                            return oldArea
                          })
                        })
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <CustomTextField
                      id="meeting_room_code"
                      name="meeting_room_code"
                      required
                      variant="outlined"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 3 }}
                      value={area.area_to_meeting_room.meeting_room_code}
                      onChange={(e) => {
                        setAreas(() => {
                          return areas.map((oldArea) => {
                            if (
                              area.id === oldArea.id &&
                              oldArea.area_to_meeting_room
                            ) {
                              return {
                                ...area,
                                area_to_meeting_room: {
                                  available_time:
                                    oldArea.area_to_meeting_room.available_time,
                                  outside_team_usable:
                                    oldArea.area_to_meeting_room
                                      .outside_team_usable,
                                  meeting_room_code: numberCheck(
                                    Number(e.target.value)
                                  )
                                    ? Number(e.target.value)
                                    : oldArea.area_to_meeting_room
                                        .meeting_room_code,
                                },
                              }
                            }
                            return oldArea
                          })
                        })
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6.5}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }}
                  >
                    {area.area_name}
                  </Grid>
                  <Grid item xs={12} sm={1.5}>
                    <CustomTypography
                      variant="caption"
                      color="#22BA9D"
                      onClick={() => {
                        setSidebarLayoutComponent("areaForm")
                        setSidebarTabType("areaForm")
                        setArea(area)
                        setAreaToSeats(area.children)
                      }}
                    >
                      編集
                    </CustomTypography>
                  </Grid>
                </Grid>
              )
            )
          }
        })}
      </>
    )
  }
)
