import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useFederatedSignIn } from "../../../models/public/useFederatedSignIn"
import { RoleContext } from "../../../providers/RoleProvider"

export const FederatedSignInPage = () => {
  const navigate = useNavigate()
  const { isAuthenticated, fetchTokenFromAzureAD } = useFederatedSignIn()
  const { isAdmin } = useContext(RoleContext)

  // URLパラメータから必要情報を取得
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get("code")
    const tenantId = params.get("tenant_id") || "デフォルトのテナントID"

    // 認証処理
    if (code) {
      fetchTokenFromAzureAD(code, tenantId).catch((e) => {
        console.error("認証処理中にエラーが発生しました:", e)
      })
    }
  }, [fetchTokenFromAzureAD])

  // 認証状態が変更された場合にリダイレクトを実行
  useEffect(() => {
    if (isAuthenticated) {
      const storedLoginType = localStorage.getItem("loginType")

      if (isAdmin && storedLoginType === "employee") {
        navigate("/employee/home")
      } else if (isAdmin) {
        navigate("/company/home")
      } else {
        navigate("/employee/home")
      }

      localStorage.removeItem("loginType")
    }
  }, [navigate, isAuthenticated, isAdmin])

  return <div>Loading...</div>
}
