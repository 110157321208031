import React, { useEffect, useRef, useState } from "react"

import {
  Typography,
  styled,
  Checkbox,
  CheckboxProps,
  SvgIcon,
  FormControlLabel,
  Slider,
  IconButton,
  Box,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useWorkHour } from "../../../../../models/company/useWorkHour"
import { renderingFlagState } from "../../../../../models/employee/useSchedule/useLayout"
import {
  LayoutResponse,
  Loading,
  Schedule,
} from "../../../../../models/employee/useSchedule/useLayout/type"
import {
  ScheduleForm,
  WhoIsWheres,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"
import { LAYOUT_PAGE_PATHNAME } from "../../../../../utils/const"
import {
  formatDateForSafari,
  formatDateForLocale,
} from "../../../../../utils/dateTimeFormat"
import { fifteenMinutesIntervalHours } from "../../../../../utils/hours"
import { EmployeeLayoutModeSelect } from "../../../layouts/common/EmployeeLayoutModeSelect"

const CustomTypography = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  color: "#22ba9d",
  lineHeight: "17px",
  letterSpacing: "0.6px",
})

const UncheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      border: "1px solid #22BA9D",
      backgroundColor: "white",
    }}
  />
)

const CheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      backgroundColor: "#22BA9D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SvgIcon
      sx={{
        color: "white",
        fontSize: "1.2rem",
      }}
    >
      <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
    </SvgIcon>
  </Box>
)

const CustomCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} />
)

interface Props {
  branchId: number
  branchName: string
  floorId: number
  floorName: string
  wholeDayFlagProps: boolean
  startDate: Date | null
  startTime: Date | null
  endTime: Date | null
  scheduleForm: ScheduleForm
  pathname: string
  layoutResponse: LayoutResponse
  setLayoutResponse: React.Dispatch<
    React.SetStateAction<Loading<LayoutResponse>>
  >
  whoIsWheres: WhoIsWheres
}

const roundDownMinutesDate = (date: Date | null): Date => {
  const roundDownMinute = 15
  const roundDownNumber = 0
  if (date != null) {
    const roundedDownMinutes =
      ((date.getMinutes() / roundDownMinute) | roundDownNumber) *
      roundDownMinute

    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      roundedDownMinutes
    )
  } else {
    return new Date()
  }
}

const findIndex = (time: Date): number => {
  return fifteenMinutesIntervalHours.findIndex((hour) => {
    return (
      hour ===
      `${time.getHours()}:${time.getMinutes() === 0 ? "00" : time.getMinutes()}`
    )
  })
}
// 拠点・フロアおよび日時の切り替えを行う
export const EmployeeCalendarLayoutHeader: React.FC<Props> = ({
  branchName,
  floorName,
  wholeDayFlagProps,
  startDate,
  startTime,
  endTime,
  scheduleForm,
  branchId,
  floorId,
  pathname,
  layoutResponse,
  setLayoutResponse,
  whoIsWheres,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const month = startDate ? startDate.getMonth() + 1 : ""
  const day = startDate ? startDate.getDate() : ""
  const weekday = startDate
    ? "(" + translations.weekdays[startDate.getDay()] + ")"
    : ""
  let roudDownstartTime = roundDownMinutesDate(startTime)
  let roundDownEndTime = roundDownMinutesDate(endTime)
  let startIndex = findIndex(roudDownstartTime)
  let endIndex = findIndex(roundDownEndTime)
  const [timeIndex, setTimeIndex] = useState<number>(startIndex)
  const [wholeDayFlag, setWholeDayFlag] = useState<boolean>(false)
  const [reservableFlag, setReservableFlag] = useState<boolean>(false)
  const date = startDate ? startDate : new Date()
  const { workHour, fetchWorkHour } = useWorkHour()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchWorkHour()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  // 時刻を切り替えた時の挙動
  const handleTimeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "object") {
      layoutChange(value)
    }
  }

  const layoutChange = (value: number[]) => {
    //時間(hh)と分(mm)を分ける
    const afterStartTime = fifteenMinutesIntervalHours[value[0]].split(":")
    const afterEndTime = fifteenMinutesIntervalHours[value[1]].split(":")
    //開始時刻を更新する
    const newStartTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      Number(afterStartTime[0]),
      Number(afterStartTime[1])
    )
    scheduleForm.schedule.start_time = newStartTime
    roudDownstartTime = roundDownMinutesDate(scheduleForm.schedule.start_time)
    startIndex = findIndex(roudDownstartTime)
    setTimeIndex(startIndex)
    //終了時刻を更新する
    const newEndTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      Number(afterEndTime[0]),
      Number(afterEndTime[1])
    )
    scheduleForm.schedule.end_time = newEndTime
    roundDownEndTime = roundDownMinutesDate(scheduleForm.schedule.end_time)
    endIndex = findIndex(roundDownEndTime)

    // スライダーで表示している時刻の範囲内にある予定を取得する
    const newSeats = layoutResponse.seats.map((seat) => {
      const schedule = seat.schedules.filter((schedule) => {
        const scheduleStartTime = new Date(
          formatDateForSafari(schedule.start_time.toString())
        )
        const scheduleEndTime = new Date(
          formatDateForSafari(schedule.end_time.toString())
        )
        return (
          (scheduleStartTime <= newStartTime &&
            scheduleEndTime > newStartTime) ||
          (scheduleStartTime < newEndTime && scheduleEndTime >= newEndTime) ||
          (scheduleStartTime >= newStartTime && scheduleEndTime <= newEndTime)
        )
      })[0]
      return { ...seat, schedule: schedule }
    })

    const newMeetingRooms = layoutResponse.meeting_rooms.map((meetingRoom) => {
      const schedulesFilteredByTime = meetingRoom.all_schedules.filter(
        (schedule) => {
          return (
            (new Date(schedule.start_time) <= newStartTime &&
              new Date(schedule.end_time) > newStartTime) ||
            (new Date(schedule.start_time) < newEndTime &&
              new Date(schedule.end_time) >= newEndTime) ||
            (new Date(schedule.start_time) >= newStartTime &&
              new Date(schedule.end_time) <= newEndTime)
          )
        }
      )

      // 同じ時間帯に複数予定が入っている場合は先に入っている予定が表示されるようにする
      const schedules = schedulesFilteredByTime.filter((schedule) => {
        return (
          schedule.schedule_information_id ===
          schedulesFilteredByTime[0].schedule_information_id
        )
      })

      return { ...meetingRoom, schedules: schedules } // scheduleプロパティに設定した予定がレイアウト上に反映される
    })
    setLayoutResponse({
      loading: false,
      value: {
        ...layoutResponse,
        seats: newSeats,
        meeting_rooms: newMeetingRooms,
      },
    })
  }

  const isFirstRender = useRef(false)
  const { renderingFlag, setRenderingFlag } = renderingFlagState()

  //「終日」がクリックされていた場合、初期状態でFlagをtrueにする
  useEffect(() => {
    if (wholeDayFlagProps) {
      setWholeDayFlag(!wholeDayFlag)
    }
    isFirstRender.current = true
    setRenderingFlag(false)
  }, [])

  // 終日選択の場合は就業時間でfilterする
  const filterSchedulesByWorkHour = (schedules: Schedule[]) => {
    return schedules.filter((schedule) => {
      // 予約時間の取得
      const scheduleStartTime = new Date(
        formatDateForSafari(schedule.start_time.toString())
      )
      const scheduleEndTime = new Date(
        formatDateForSafari(schedule.end_time.toString())
      )

      const scheduleStartTimeString = formatDateForLocale(scheduleStartTime)
      const scheduleEndTimeString = formatDateForLocale(scheduleEndTime)

      // 就業時間の取得
      const workStartTime = new Date(
        formatDateForSafari(workHour.am_start_time.toString())
      )
      const workEndTime = new Date(
        formatDateForSafari(workHour.pm_end_time.toString())
      )

      const workStartTimeString = formatDateForLocale(workStartTime)
      const workEndTimeString = formatDateForLocale(workEndTime)

      const isWithinWorkHours =
        scheduleStartTimeString < workEndTimeString &&
        scheduleEndTimeString > workStartTimeString

      return isWithinWorkHours
    })
  }

  //「終日」がクリックされた場合。stateを更新する
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      scheduleForm.schedule.whole_day_flag = wholeDayFlag
      if (wholeDayFlag) {
        const selectedDate = startDate
          ? new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            )
          : new Date()
        // 終日フラグが有効の場合のフィルタリングに日付チェックを追加
        const newSeats = layoutResponse.seats.map((seat) => {
          const filteredSchedules = filterSchedulesByWorkHour(seat.schedules)
          // 個々のseatに対してフィルタをかけ、最初にマッチしたスケジュールを取得
          const firstMatchingSchedule = filteredSchedules.find((schedule) => {
            const scheduleStartDate = new Date(
              formatDateForSafari(schedule.start_time.toString())
            )
            return (
              selectedDate.toDateString() === scheduleStartDate.toDateString()
            )
          })
          return { ...seat, schedule: firstMatchingSchedule }
        })
        const newMeetingRooms = layoutResponse.meeting_rooms.map(
          (meetingRoom) => {
            const filteredSchedules = filterSchedulesByWorkHour(
              meetingRoom.all_schedules
            )
            const schedulesFilteredByTime = filteredSchedules.filter(
              (schedule) => {
                const scheduleStartDate = new Date(schedule.start_time)
                const scheduleEndDate = new Date(schedule.end_time)
                // 同じくスケジュールの開始日 or 終了日が選択した日と一致するか
                return (
                  selectedDate.toDateString() ===
                    scheduleStartDate.toDateString() ||
                  selectedDate.toDateString() === scheduleEndDate.toDateString()
                )
              }
            )

            // 同じ時間帯に複数予定が入っている場合は先に入っている予定が表示されるようにする
            const schedules = schedulesFilteredByTime.filter((schedule) => {
              return (
                schedule.schedule_information_id ===
                schedulesFilteredByTime[0].schedule_information_id
              )
            })

            return { ...meetingRoom, schedules: schedules }
          }
        )
        setLayoutResponse({
          loading: false,
          value: {
            ...layoutResponse,
            seats: newSeats,
            meeting_rooms: newMeetingRooms,
          },
        })
      } else {
        layoutChange([startIndex, endIndex])
      }
    }
  }, [wholeDayFlag, layoutResponse, startDate, filterSchedulesByWorkHour])

  //「終日」がクリックされていた場合、初期状態でFlagをtrueにする
  useEffect(() => {
    whoIsWheres.forEach((whoIsWhere) => {
      if (
        whoIsWhere.reservable.reservable_id != null &&
        reservableFlag == false
      ) {
        setReservableFlag(true)
      }
    })
  }, [whoIsWheres])

  return (
    <>
      <Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box>
              <CustomTypography
                sx={{
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
              >
                {translations.Location}
              </CustomTypography>
            </Box>
            <Box>
              <CustomTypography
                sx={{
                  color: "#707070",
                  background: "#F8F8F8",
                  lineHeight: "17px",
                  letterSpacing: "0.6px",
                  padding: "12px 15px",
                  borderRadius: "6px",
                }}
              >
                {branchName}
              </CustomTypography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <CustomTypography
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {translations.Floor}
              </CustomTypography>
            </Box>
            <Box>
              <CustomTypography
                sx={{
                  color: "#707070",
                  background: "#F8F8F8",
                  lineHeight: "17px",
                  letterSpacing: "0.6px",
                  padding: "12px 15px",
                  borderRadius: "6px",
                }}
              >
                {floorName}
              </CustomTypography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <CustomTypography
              sx={{
                color: "black",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {`${month}${translations.Month}${day}${translations.Day}${weekday}`}
            </CustomTypography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <CustomCheckbox
                  defaultChecked={wholeDayFlagProps}
                  onChange={() => setWholeDayFlag(!wholeDayFlag)}
                />
              }
              label={translations.AllDay}
              componentsProps={{
                typography: {
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  color: "#454545",
                  lineHeight: "19px",
                  letterSpacing: "0.7px",
                  marginRight: "-1px",
                },
              }}
            />
          </Box>
          <Box sx={{ fontSize: "12px", fontFamily: "Noto Sans" }}>
            {fifteenMinutesIntervalHours[startIndex]}
          </Box>
          <Box flexGrow="1" margin="0 20px">
            <Slider
              disabled={wholeDayFlag || renderingFlag || reservableFlag}
              max={fifteenMinutesIntervalHours.length - 1}
              step={1}
              disableSwap
              valueLabelFormat={(value: number) => {
                return fifteenMinutesIntervalHours[value]
              }}
              defaultValue={[startIndex, endIndex]}
              onChange={handleTimeChange}
              track="inverted"
              sx={{
                color: "#f0f0f0",
                height: "10px",
                padding: "0",
                opacity: "1",
                "& .MuiSlider-rail": {
                  color: "#F0F0F0",
                },
                "& .MuiSlider-thumb": {
                  height: "10px",
                  width: "10px",
                  borderRadius: "10px",
                  color: "#22ba9d",
                },
                "& .MuiSlider-track": {
                  height: "8px",
                  color: "#22ba9d",
                },
                "& .MuiSlider-thumb::before": {
                  boxShadow: "none",
                },
                "& .MuiSlider-thumb::after": {
                  width: "0",
                  height: "0",
                },
              }}
            />
          </Box>
          <Box sx={{ fontSize: "12px", fontFamily: "Noto Sans" }}>
            {fifteenMinutesIntervalHours[endIndex]}
          </Box>
          {LAYOUT_PAGE_PATHNAME.includes(pathname) ? (
            <Box sx={{ marginLeft: "20px" }}>
              <EmployeeLayoutModeSelect
                type={"reservation"}
                branchId={branchId}
                floorId={floorId}
                date={date}
                wholeDayFlag={wholeDayFlag}
                timeIndex={timeIndex}
                time={fifteenMinutesIntervalHours[0]}
              />
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
    </>
  )
}
