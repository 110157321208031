const en = {
  checkInOut: "Desk Check-in/out",
  latest: "Weekly Schedule",
  seatStatus: "Live Workplace Status",
  NewMessage: "NEW MESSAGES",
  Subject: "SUBJECT",
  From: "FROM",
  Repeat: "REPEAT",
  Location: "LOCATION",
  dates: "DATE",
  TermsService: "Terms of Service",
  and: "&",
  PrivacyPolicy: "Privacy Policy",
  everyWeek: "every week",
  everyWeeks: "every weeks",
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  week: "",
  date_format: "MM/dd/yyyy",
}
export default en
