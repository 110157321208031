import { Auth } from "aws-amplify"

import React, { useContext, useState, useEffect } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  Box,
  Button,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material"

import { checkUserCompanyTypeRequest } from "../../../../api/company/checkUserRequest"
import { LoginType } from "../../../../models/public/useAuth/login/type"
import { AuthContext } from "../../../../providers/AuthProvider"
import { pageType } from "../../../../utils/location"
import { redirectToSAMLLogin } from "../../../sso/loginRequest"
import { AlertErrorContent } from "../../AlertContent"
import { ErrorText } from "../../ErrorText"
import { RequiredFlag } from "../../RequiredFlag"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(53),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
  whiteSpace: "pre-line",
}))

interface Props {
  authData: LoginType
  control: Control<LoginType>
  handleSubmit: UseFormHandleSubmit<LoginType>
  onSubmit: SubmitHandler<LoginType>
  errors: FieldErrors<LoginType>
  isValid: boolean
  open: boolean
  errorMessages: string[]
  setLanguage: (lang: string) => void
}

export const LoginForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  isValid,
  errorMessages,
  setLanguage,
}: Props) => {
  // ログインしたら言語が切り替わるようにする
  //SSOユーザーかどうかは招待メールを送信したかどかで判断
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)

  const handleLogin = async (data: LoginType) => {
    errorMessages.length = 0

    try {
      const userTypeResponse = await checkUserCompanyTypeRequest(data.email)
      if (userTypeResponse.error) {
        // エラーメッセージを処理
        userTypeResponse.error.forEach((errorMessage) => {
          console.error("Error during login process:", errorMessage)
        })

        // ユーザーがDBに存在しない場合、通常のログイン処理を行う
        try {
          await onSubmit(data)
          const currentUser = await Auth.currentAuthenticatedUser()
          const userID = currentUser ? currentUser.attributes.sub : "default"
          const storedLanguage = localStorage.getItem(`language_${userID}`)
          if (storedLanguage) {
            setLanguage(storedLanguage)
          }
          setOpen(false)
        } catch (error) {
          // ログイン処理中にエラーが発生した場合
          errorMessages.push("ログインに失敗しました")
          setOpen(true)
        }
      } else if (userTypeResponse.data === "SSO") {
        // SSOユーザーの場合
        if (data.password) {
          errorMessages.push(
            "AzureAD認証になっております\nメールアドレスのみでログインしてください"
          )
          setOpen(true)
        } else {
          localStorage.setItem("loginType", "company")
          setOpen(false)
          redirectToSAMLLogin()
        }
      } else {
        // 通常のユーザーの場合
        if (!data.password) {
          errorMessages.push("パスワードを入力してください")
          setOpen(true)
        } else {
          await onSubmit(data)
          const currentUser = await Auth.currentAuthenticatedUser()
          const userID = currentUser ? currentUser.attributes.sub : "default"
          const storedLanguage = localStorage.getItem(`language_${userID}`)
          if (storedLanguage) {
            setLanguage(storedLanguage)
          }
          setOpen(false)
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        errorMessages.length = 0
        setErrorMessage(error.message)
        setErrorMessageOpen(true)
      }
    }
  }

  const clearErrorMessages = () => {
    setOpen(false)
  }

  useEffect(() => {
    const postSignOutRedirect = localStorage.getItem("postSignOutRedirect")
    if (postSignOutRedirect) {
      window.location.href = postSignOutRedirect
      localStorage.removeItem("postSignOutRedirect")
    }
  }, [])

  return (
    <>
      <AlertErrorContent />
      <CustomGridContainer
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        spacing={3}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <img
              src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
              alt="WORK AGILEロゴ"
            />
          </Grid>
        </Grid>
        <CustomGridItem item>
          <Typography variant="subtitle1" color="inherit" align="center">
            おかえりなさい！
            <Typography>アカウントにログインしてください</Typography>
          </Typography>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                メールアドレス
                <RequiredFlag />
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  id="email"
                  name="email"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                パスワード
                <RequiredFlag />
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  onChange={(e) => {
                    clearErrorMessages()
                    field.onChange(e)
                  }}
                  required
                  id="password"
                  name="password"
                  fullWidth
                  variant="outlined"
                  type="password"
                />
              )}
            />
          </Grid>
        </CustomGridItem>
        {/* <Grid item>
          <Controller
            name="rememberLoginData"
            render={({ field }) => (
              <FormControlLabel
                sx={{ marginRight: "0" }}
                label={
                  <Typography variant="subtitle2">
                    メールアドレスとパスワードを記憶する
                  </Typography>
                }
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    id="rememberLoginData"
                  />
                }
              />
            )}
            control={control}
          />
        </Grid> */}
        {open && (
          <Grid item>
            <ErrorMessageContainer textAlign="center">
              {ErrorText(errorMessages)}
            </ErrorMessageContainer>
          </Grid>
        )}
        <Grid item>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <CustomButton
              onClick={handleSubmit(handleLogin)}
              variant="contained"
              color="primary"
            >
              ログイン
            </CustomButton>
          </Box>
        </Grid>
        <Grid item>
          <Typography align="center">
            <Link
              component={RouterLink}
              to={`/${pageType}/forgot-password`}
              variant="caption"
              color="#43425D"
            >
              パスワードをお忘れの方はこちら
            </Link>
          </Typography>
        </Grid>
      </CustomGridContainer>
    </>
  )
}
