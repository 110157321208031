import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Modal, Button } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleCheckIn } from "../../../../models/mobile/useSchedule/checkIn"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { AlertError } from "../../../public/AlertError"
import { Loading } from "../../../public/Loading"
import { CheckInNotReservedModal } from "../CheckInNotReservedModal"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

interface Props {
  companyRelations: CompanyRelationsType
}

// チェックインのメイン画面
export const CheckIn = ({ companyRelations }: Props) => {
  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const {
    branchName,
    floorNumber,
    checkInType,
    checkInTarget,
    checkIn,
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    handleInstantCheckIn,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    setCheckInStateFromQueryString,
    hasAnotherSchedule,
    dataLoading,
    hasAnotherCheckIn,
  } = useScheduleCheckIn()

  useEffect(() => {
    if (companyRelations) {
      setCheckInStateFromQueryString(companyRelations)
    }
  }, [companyRelations])

  // PrivateRouteだとクエリパラメータを読み込めないのでここでリダイレクトさせる
  useEffect(() => {
    if (!currentUser) {
      navigate(
        `/mobile/check-in/login?floor_id=${queryParams.get(
          "floor_id"
        )}&id=${queryParams.get("id")}&type=${queryParams.get(
          "type"
        )}&name=${queryParams.get("name")}`
      )
    }
  }, [])

  return (
    <>
      {dataLoading ? (
        <Loading type="content" loading={dataLoading} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            <CustomTypography variant="h5">{branchName}</CustomTypography>
            <CustomTypography variant="h5">{floorNumber}F</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            {checkInType === "Seat" && (
              <CustomTypography variant="h4">
                {translations.Desk}：{checkInTarget}
              </CustomTypography>
            )}
            {checkInType === "MeetingRoom" && (
              <CustomTypography variant="h4">
                {translations.RoomName}：{checkInTarget}
              </CustomTypography>
            )}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CustomTypography>{translations.YouCheckIn}</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={checkIn}
            >
              <Typography fontWeight="bold">{translations.CheckIn}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/layouts")}
            >
              <Typography fontWeight="bold">
                {translations.DeskStatus}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/home")}
            >
              <Typography fontWeight="bold">{translations.Home}</Typography>
            </Button>
          </Grid>
          <Modal
            open={notReserved}
            onClose={() => setNotReserved(false)}
            disableAutoFocus
          >
            <ModalBox>
              <CheckInNotReservedModal
                notReservedModalMessage={notReservedModalMessage}
                hours={reservationModalHours}
                minutes={reservationModalMinutes}
                reservationHour={reservationHour}
                reservationMinutes={reservationMinutes}
                handleReservationHourChange={handleReservationHourChange}
                handleReservationMinutesChange={handleReservationMinutesChange}
                setNotReserved={setNotReserved}
                handleInstantCheckIn={handleInstantCheckIn}
                hasAnotherSchedule={hasAnotherSchedule}
                hasAnotherCheckIn={hasAnotherCheckIn}
              />
            </ModalBox>
          </Modal>
          <AlertError
            isMessageOpen={checkInError}
            alertMessage={checkInErrorMessage}
            closeMessage={() => setCheckInError(false)}
          />
        </Grid>
      )}
    </>
  )
}
