import { useEffect, useState } from "react"

import { SelectChangeEvent } from "@mui/material"

import { fetchLayoutDashboardRequest } from "../../../api/company/layoutRequest"
import { fetchRSSFeed } from "../../../api/company/wordPressRequest"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { downloadLayoutImage } from "../../../utils/downloadLayoutImage"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import {
  AreaType,
  MeetingRoomType,
  SeatType,
  LayoutType,
  ArticleType,
} from "./type"

// TODO エリアの描画実装が終わり次第不要なら削除
/* 定数
  SPECIFIC: 特定
  ALL: 全て
  DEFAULT_COLOR_CODE: エリア・会議室の枠線の色
*/
export const SPECIFIC = 0
export const ALL = 1
export const DEFAULT_COLOR_CODE = "#F4B461"

const layoutData: LayoutType = {
  x: 0,
  y: 0,
  scale: 0,
}

// レイアウト
export const useDashboard = () => {
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)
  const [companyId, setCompanyId] = useState<number>(0)
  const [layoutImg, setLayoutImg] = useState<string>("")
  const [layout, setLayout] = useState<LayoutType | undefined>(undefined)
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])
  const [areas, setAreas] = useState<AreaType[]>([])
  const [seats, setSeats] = useState<SeatType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [articlesLoading, setArticlesLoading] = useState<boolean>(false)
  const errorMessageObject = useAlertMessage()
  const [defectArticles, setDefectArticles] = useState<ArticleType[]>([])
  const [updateArticles, setUpdateArticles] = useState<ArticleType[]>([])

  // 最新のレイアウト情報の取得
  // レイアウト情報を反映させる
  const layoutReflection = async () => {
    try {
      const response = await fetchLayoutDashboardRequest({
        floor_id: floorId,
      })

      if (response.data && response.status === 200) {
        if (response.data.layout.layout_image === "") {
          setLayoutImg("")
          setSeats([])
          setMeetingRooms([])
          setAreas([])
          setLayout(layoutData)
          errorMessageObject.handleSetMessage(
            "公開しているレイアウトはございません"
          )
          errorMessageObject.openMessage()
        }

        // 従業員画像をS3からダウンロードする
        const seatObject: SeatType[] = []
        await Promise.all(
          response.data.layout.seats.map(async (seat) => {
            if (seat.schedule?.userable?.icon) {
              const resultImageBlob = await downloadIcon(
                companyId,
                seat.schedule.userable.id,
                seat.schedule.userable.icon
              )
              seat.schedule.image_blob = resultImageBlob ? resultImageBlob : ""
            }
            seatObject.push(seat)
          })
        )

        const meetingRoomObject: MeetingRoomType[] = []
        await Promise.all(
          response.data.layout.meeting_rooms.map(async (meetingRoom) => {
            await Promise.all(
              meetingRoom.schedules.map(async (schedule) => {
                if (schedule.userable.icon) {
                  const resultImageBlob = await downloadIcon(
                    companyId,
                    schedule.userable.id,
                    schedule.userable.icon
                  )
                  schedule.image_blob = resultImageBlob ? resultImageBlob : ""
                }
              })
            )

            meetingRoomObject.push(meetingRoom)
          })
        )

        const areaObject: AreaType[] = []
        await Promise.all(
          response.data.layout.areas.map(async (area) => {
            const areaToSeatObject: SeatType[] = []
            await Promise.all(
              area.children.map(async (seat) => {
                areaToSeatObject.push(seat)
              })
            )
            areaObject.push({
              ...area,
              children: areaToSeatObject,
            })
          })
        )

        setSeats(seatObject)
        setMeetingRooms(meetingRoomObject)
        setAreas(areaObject)
        if (response.data.layout.layout_image) {
          const result = await downloadLayoutImage(
            companyId,
            branchId,
            floorId,
            response.data.layout.layout_image
          )
          if (result) {
            setLayoutImg(URL.createObjectURL(result.Body as Blob))
            setLayout({
              x: response.data.layout.x,
              y: response.data.layout.y,
              scale: response.data.layout.scale,
            })
          }
        }
      }
    } catch (error) {
      setLayoutImg("")
      setSeats([])
      setMeetingRooms([])
      setAreas([])
      setLayout(layoutData)
    }
  }

  // 拠点とフロアの切り替え
  const changeSelectValue = (
    e: SelectChangeEvent<number>,
    companyId: number
  ) => {
    setCompanyId(companyId)
    switch (e.target.name) {
      case "branch_id":
        setFloorId(0)
        return setBranchId(Number(e.target.value))
      case "floor_id":
        return setFloorId(Number(e.target.value))
    }
  }

  useEffect(() => {
    if (floorId !== 0) {
      setLoading(true)
      layoutReflection().then(() => setLoading(false))
    }
  }, [floorId])

  useEffect(() => {
    const getArticles = async () => {
      setArticlesLoading(true)
      const { data } = await fetchRSSFeed()
      setUpdateArticles(data?.updates ?? [])
      setDefectArticles(data?.defects ?? [])
      setArticlesLoading(false)
    }
    getArticles()
  }, [])

  return {
    branchId,
    floorId,
    changeSelectValue,
    layoutImg,
    layout,
    seats,
    meetingRooms,
    areas,
    loading,
    setFloorId,
    setBranchId,
    setCompanyId,
    errorMessageObject,
    updateArticles,
    defectArticles,
    articlesLoading,
  }
}
