const en = {
  WelcomeBack: "Welcome back! ",
  PleaseLoginAccount: "Please login to your account.",
  EmailAddress: "E-mail address(required)",
  Password: "Password(required)",
  Login: "Login",
  ForgotPassword: "Forgot your password?",
  LoginFailed: "Login failed.",
}
export default en
