import { api, ApiResponse } from "../config/signageAxiosConfig"

export const checkUserEmployeeTypeRequest = async (
  email: string
): Promise<ApiResponse<"SSO" | "Normal">> => {
  try {
    const response = await api.post("/employee/check_user/check_user_type", {
      email,
    })
    return { data: response.data.user_type }
  } catch (error: any) {
    // エラー情報をコンソールに出力
    console.error("Error occurred in checkUserEmployeeTypeRequest:", error)
    if (error.response && error.response.data) {
      // サーバーからの応答がある場合
      console.error("Error response data:", error.response.data)
      return { error: [error.response.data.error || "Server error occurred"] }
    } else {
      // サーバーからの応答がない場合（ネットワークエラーなど）
      console.error("Network or server error occurred")
      return { error: ["Network or server error occurred"] }
    }
  }
}
