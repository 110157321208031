import React from "react"

import { Grid, Box, Typography, styled } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { WhoIsWheres } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"

interface Props {
  whoIsWheres: WhoIsWheres
}

const CustomTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: theme.palette.primary.main,
}))

const CustomNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  color: "#454545",
}))

const EmployeeAndSelectedSeatList = styled(Grid)(({ theme }) => ({
  width: "70%",
  marginTop: theme.spacing(0.5),
  overflowY: "scroll",
  maxHeight: theme.spacing(28),
  "&::-webkit-scrollbar": {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

export const EmployeeCalendarLayoutSelectedSeat: React.FC<Props> = ({
  whoIsWheres,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // ゲストには座席を割り当てないと合意済み。よってそもそもドラッグアンドドロップさせない
  const onlyEmployees = whoIsWheres.filter((whoIsWhere) => {
    return whoIsWhere.userable.userable_type === "Employee"
  })
  return (
    <>
      <Box display="flex" margin="15px 0 20px 0">
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/seat-without-circle.svg`}
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        <CustomTitleTypography>
          {translations.SelectedDesk}
        </CustomTitleTypography>
      </Box>
      <EmployeeAndSelectedSeatList container alignItems="center">
        {onlyEmployees.map((whoIsWhere, index) => (
          <Grid
            item
            sm={11}
            key={index}
            sx={{
              margin: "0 0 5px 25px",
              padding: theme.spacing(0.25, 1.5),
              display: "flex",
            }}
          >
            <CustomNameTypography marginRight="10px">
              {whoIsWhere.userable.last_name}
            </CustomNameTypography>
            <CustomNameTypography>
              {whoIsWhere.userable.first_name}
            </CustomNameTypography>
            <Typography
              fontSize="14px"
              fontFamily="Noto Sans"
              mr="1rem"
              display="inline"
            >
              {whoIsWhere.reservable.name}
            </Typography>
          </Grid>
        ))}
      </EmployeeAndSelectedSeatList>
    </>
  )
}
