import React from "react"

import { Box, styled, Typography } from "@mui/material"

import { EmployeeLayoutHeader } from "../../../components/employee/layouts/EmployeeLayoutHeader"
import { EmployeeLayoutMain } from "../../../components/employee/layouts/EmployeeLayoutMain"
import { EmployeeLayoutSidebar } from "../../../components/employee/layouts/EmployeeLayoutSideBar"
import { ContentEmployeePaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { theme } from "../../../config/theme"
import { useLayout } from "../../../models/employee/useLayout"
import { EmployeeLayoutRequestType } from "../../../models/employee/useLayout/type"

export type Employees = {
  id: number
  last_name: string
  first_name: string
}[]

export const CustomSideberBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
})

export const CustomTabBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px 10px 0 0",
  padding: "5px 0",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 8px gray",
  },
})

export const EmployeeLayoutPage = () => {
  const {
    branchId,
    floorId,
    changeSelectValue,
    layoutImg,
    layout,
    seats,
    meetingRooms,
    fetchLayout,
    handleSeatHighLight,
    filterSeatSchedules,
    handleEmployeePlaceSearch,
    filterMeetingRoomSchedules,
    selectedSeat,
    selectedMeetingRoom,
    setSelectedSeat,
    setSelectedMeetingRoom,
    handleFilterWholeDaySchedule,
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    formatDate,
    setStartDate,
    setEndDate,
    timeIndex,
    setTimeIndex,
    userNotExists,
    userNotExistsMessage,
    setUserNotExists,
    wholeDayFlag,
    setWholeDayFlag,
    loading,
    handleFilterSchedule,
    areas,
    companyRelations,
    selectedBranch,
  } = useLayout()

  const handleLayoutChange = async (params: EmployeeLayoutRequestType) => {
    await fetchLayout(params, companyRelations.id)
  }

  return (
    <>
      <ContentTemplate>
        <ContentEmployeePaper>
          {loading && <Loading type="content" loading={loading} />}
          <Box display="flex" width="100%">
            <Box flexGrow="1" sx={{ paddingRight: theme.spacing(1.875) }}>
              <Box>
                <Box alignItems="center">
                  <Box width="100%">
                    <EmployeeLayoutHeader
                      changeSelectValue={changeSelectValue}
                      companyRelations={companyRelations}
                      branchId={branchId}
                      floorId={floorId}
                      handleLayoutChange={handleLayoutChange}
                      filterSeatSchedules={filterSeatSchedules}
                      filterMeetingRoomSchedules={filterMeetingRoomSchedules}
                      handleFilterWholeDaySchedule={
                        handleFilterWholeDaySchedule
                      }
                      date={date}
                      setDate={setDate}
                      previousDate={previousDate}
                      nextDate={nextDate}
                      previousWeekday={previousWeekday}
                      nextWeekday={nextWeekday}
                      weekdayNumber={weekdayNumber}
                      setWeekdayNumber={setWeekdayNumber}
                      dayOfWeek={dayOfWeek}
                      startDate={startDate}
                      endDate={endDate}
                      timeIndex={timeIndex}
                      setTimeIndex={setTimeIndex}
                      formatDate={formatDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      wholeDayFlag={wholeDayFlag}
                      setWholeDayFlag={setWholeDayFlag}
                      loading={loading}
                      handleFilterSchedule={handleFilterSchedule}
                      selectedBranch={selectedBranch}
                    />
                  </Box>
                </Box>
                <EmployeeLayoutMain
                  layoutImg={layoutImg}
                  layout={layout}
                  seats={seats}
                  meetingRooms={meetingRooms}
                  areas={areas}
                  setSelectedSeat={setSelectedSeat}
                  setSelectedMeetingRoom={setSelectedMeetingRoom}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "270px",
                paddingLeft: theme.spacing(1.875),
                margin: "0",
                display: "flex",
                justifyContent: "center",
                borderLeft: "1px solid #E5E5E5",
              }}
            >
              <EmployeeLayoutSidebar
                floorId={floorId}
                handleSeatHighLight={handleSeatHighLight}
                handleEmployeePlaceSearch={handleEmployeePlaceSearch}
                selectedSeat={selectedSeat}
                selectedMeetingRoom={selectedMeetingRoom}
                userNotExists={userNotExists}
                userNotExistsMessage={userNotExistsMessage}
                setUserNotExists={setUserNotExists}
              />
            </Box>
          </Box>
        </ContentEmployeePaper>
      </ContentTemplate>
    </>
  )
}
