import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef } from "react"

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import { Box, Button, styled, Popover, Typography } from "@mui/material"

import { theme } from "../../../../config/theme"
import {
  SeatType,
  MeetingRoomType,
  AreaType,
} from "../../../../models/signage/useFloor/type"
import { AvailableSeat } from "../../../public/AvailableSeat"
import { NotCheckInSeat } from "../../../public/NotCheckInSeat"
import { UnavailableSeat } from "../../../public/UnavailableSeat"
import { SignageFloorArea } from "./Areas"
import { SignageFloorMeetingRoom } from "./MeetingRooms"
import { SignageFloorSeat } from "./Seats"

const ZoomButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

const ZoomButtonBox = styled(Box)(() => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(() => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

interface Props {
  layoutImg: string
  seats: SeatType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
}

export const SignageFloorMap: React.FC<Props> = ({
  layoutImg,
  seats,
  meetingRooms,
  areas,
}: Props) => {
  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <>
      <ZoomButtonContainer>
        <Button
          sx={{ mr: 2, borderRadius: "16px" }}
          type="submit"
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClick}
        >
          アイコン凡例
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: -15,
            horizontal: -10,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              height: "56px",
              width: "650px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="20%"
            >
              <AvailableSeat />
              <Typography variant="body1" pl={"7px"}>
                予約・利用可
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="15%"
            >
              <NotCheckInSeat />
              <Typography variant="body1" pl={"7px"}>
                予約中
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="25%"
            >
              <AccountCircleOutlinedIcon />
              <Typography variant="body1" pl={"7px"}>
                チェックイン中
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="25%"
            >
              <UnavailableSeat />
              <Typography variant="body1" pl={"7px"}>
                予約・利用不可
              </Typography>
            </Box>
          </Box>
        </Popover>
        <ZoomButtonBox color="primary">
          <MinusButton
            onClick={async () => {
              // 最小10%を超えないようにする
              if (
                layoutImageRef.current?.state?.scale &&
                layoutImageRef.current?.state?.scale <= 0.1
              ) {
                return
              } else {
                layoutImageRef.current?.setTransform(
                  0,
                  0,
                  layoutImageRef.current?.state.scale - 0.1
                )
              }
            }}
          >
            ー
          </MinusButton>
          <PlusButton
            onClick={async () => {
              // 最大100%を超えないようにする
              if (
                layoutImageRef.current?.state?.scale &&
                layoutImageRef.current?.state?.scale >= 1.0
              ) {
                return
              } else {
                layoutImageRef.current?.setTransform(
                  0,
                  0,
                  layoutImageRef.current?.state.scale + 0.1
                )
              }
            }}
          >
            ＋
          </PlusButton>
        </ZoomButtonBox>
        <Button
          sx={{ ml: 2, borderRadius: "16px" }}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => window.location.reload()}
        >
          再読み込み
        </Button>
      </ZoomButtonContainer>
      <TransformWrapper
        initialScale={0.5}
        disabled={false}
        minScale={0.1}
        maxScale={1.0}
        wheel={{ disabled: false, step: 0.05 }}
        panning={{
          disabled: false,
        }}
        ref={layoutImageRef}
      >
        <TransformComponent
          wrapperStyle={{ maxWidth: "100%", maxHeight: "600px" }}
        >
          <img id="layout-image" src={layoutImg} />
          {seats.map((seat) => {
            return <SignageFloorSeat key={`seat${seat.id}`} seat={seat} />
          })}
          {meetingRooms.map((meetingRoom) => {
            return (
              <SignageFloorMeetingRoom
                key={`meetingRoom${meetingRoom.id}`}
                meetingRoom={meetingRoom}
              />
            )
          })}
          {areas.map((area) => {
            return (
              <>
                <SignageFloorArea key={`area${area.id}`} area={area} />
                {area.children.map((seat) => {
                  return <SignageFloorSeat key={`seat${seat.id}`} seat={seat} />
                })}
              </>
            )
          })}
        </TransformComponent>
      </TransformWrapper>
    </>
  )
}
