import React from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Typography, styled, Button, Box } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

interface Props {
  companyRelations: CompanyRelationsType
  checkInData: CheckInData
}

// 座席が指定席だった場合に表示するコンポーネント
export const CheckInReservationSeat = ({ checkInData }: Props) => {
  const navigate = useNavigate()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography variant="h5">
            {checkInData.branchName}
          </CustomTypography>
          <CustomTypography variant="h5">
            {" "}
            {checkInData.floorNumber}F
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography variant="h4">
            {translations.Desk}：{checkInData.seatName}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography align="center">
            {translations.ReservedSeat}
            <br />
            {translations.PleaseFindAnotherSeat}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/employee/layouts")}
          >
            <Typography fontWeight="bold">{translations.DeskStatus}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
          >
            <Typography fontWeight="bold">{translations.Home}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
