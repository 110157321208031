import { getDate, getDay } from "date-fns"

import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Grid } from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { EmployeeType } from "../../../../../models/employee/useSchedule/type"
import { DragEmployee } from "../../../../../models/employee/useSchedule/useLayout/type"
import { useNewScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm"
import {
  ReservableType,
  ScheduleForm,
  WhoIsWheres,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { AuthContext } from "../../../../../providers/AuthProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"
import { LAYOUT_PAGE_PATHNAME } from "../../../../../utils/const"
import { useOpen } from "../../../../../utils/isOpen"
import { ReflectButton } from "../../../../public/ReflectButton"
import { EmployeeCalendarLayoutSearch } from "../EmployeeCalendarLayoutSearch"
import { EmployeeCalendarLayoutSelectedSeat } from "../EmployeeCalendarLayoutSelectedSeat"
import { CreateScheduleModal } from "./CreateScheduleModal"

interface Props {
  scheduleForm: ScheduleForm
  whoIsWheres: WhoIsWheres
  setDragEmployee: React.Dispatch<React.SetStateAction<DragEmployee>>
  pathname: string
  branchName: string
  floorName: string
  monthlySetting?: string
}

interface LocationState {
  branchId?: number
  floorId?: number
}

const getThisWeek = () => {
  const today = new Date()
  return Math.floor((today.getDate() - today.getDay() + 12) / 7)
}
// 席状況のサイドバー
export const EmployeeCalendarLayoutSidebar = ({
  scheduleForm,
  whoIsWheres,
  setDragEmployee,
  pathname,
  branchName,
  floorName,
  monthlySetting,
}: Props) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation() as { state: LocationState }

  const employees = whoIsWheres
    .filter((x) => x.userable.userable_type === "Employee")
    .map(
      (x) =>
        ({
          id: x.userable.userable_id,
          last_name: x.userable.last_name,
          first_name: x.userable.first_name,
          email: x.userable.email,
        } as EmployeeType)
    )
  scheduleForm.schedule.share_schedule.who_is_wheres = whoIsWheres
  const { onNewScheduleSubmit, errorMessages, setErrorMessages } =
    useNewScheduleForm(employees, scheduleForm)

  const handleClick = () => {
    if (LAYOUT_PAGE_PATHNAME.includes(pathname)) {
      // 自分一人しか参加しない かつ (席のみ予約 または どこも予約しない) であれば、モーダルのみでスケジュール作成可能
      if (
        whoIsWheres.length === 1 &&
        whoIsWheres[0].userable.email === currentUser?.attributes?.email &&
        (whoIsWheres[0].reservable.reservable_type === undefined ||
          whoIsWheres[0].reservable.reservable_type === ReservableType.SEAT)
      ) {
        const reservable_number =
          scheduleForm.schedule.share_schedule.who_is_wheres[0].reservable.name
        // 予約タイトル自動作成（拠点名-フロア名-座席番号）
        scheduleForm.schedule.schedule_title = reservable_number
          ? `${branchName}-${floorName}-${String(reservable_number)}`
          : translations.NoTitle
        open()
      }
      // そうでなければ、スケジュール新規作成画面に遷移する
      else {
        // 選択した従業員に自分以外も含まれていれば、予約設定画面に遷移
        // 初期値の入力
        scheduleForm.schedule.weekly_or_monthly = "weekly"
        scheduleForm.schedule.weekly = {
          every_week: 1,
          days: new Array(7).fill(false),
        }
        scheduleForm.schedule.monthly = {
          dayly: {
            monthly: 1,
            day: getDate(scheduleForm.schedule.start_date!),
          },
          dayOfWeekly: {
            monthly: 1,
            weekly: getThisWeek(),
            dayOfWeek: getDay(scheduleForm.schedule.start_date!),
          },
        }
        navigate(
          scheduleForm.schedule.id === 0
            ? "/employee/calendar/new"
            : `/employee/calendar/edit/${scheduleForm.schedule.id}`,
          {
            state: {
              scheduleForm: scheduleForm,
              scheduleInformation: { scheduleForm },
              whoIsWheres: whoIsWheres,
              navigateFrom: "/employee/calendar/days",
            },
          }
        )
      }
    } else {
      // 新規スケジュール作成画面から遷移してきた場合
      navigate(
        scheduleForm.schedule.id === 0
          ? "/employee/calendar/new"
          : `/employee/calendar/edit/${scheduleForm.schedule.id}`,
        {
          state: {
            branchId: location.state?.branchId,
            floorId: location.state?.floorId,
            monthlySetting: monthlySetting,
            scheduleForm: scheduleForm,
            scheduleInformation: { scheduleForm },
            navigateFrom: "/employee/calendar/days",
          },
        }
      )
    }
  }

  // 座席を変更した場合エラーメッセージを消す
  useEffect(() => {
    setErrorMessages([])
  }, [
    scheduleForm.schedule.share_schedule.who_is_wheres[0].reservable
      .reservable_id,
  ])
  const { isOpen, open, close } = useOpen()

  return (
    <Grid container direction="column" height="100%">
      <Grid item sm={5.5}>
        <EmployeeCalendarLayoutSearch
          whoIsWheres={whoIsWheres}
          setDragEmployee={setDragEmployee}
        />
      </Grid>
      <Grid item sm={5.5} borderTop="solid 1px #E5E5E5" pb="1rem">
        <EmployeeCalendarLayoutSelectedSeat whoIsWheres={whoIsWheres} />
      </Grid>
      <Grid item sm={1} display="flex" justifyContent="center">
        <ReflectButton
          label={translations.ConfirmReservation}
          handleClick={handleClick}
        />
      </Grid>

      {/* 予約確認モーダル */}
      <CreateScheduleModal
        isOpen={isOpen}
        hasSeat={whoIsWheres.some(
          (x) =>
            x.reservable &&
            x.reservable.reservable_type === ReservableType.SEAT &&
            x.reservable.reservable_id
        )}
        close={close}
        scheduleForm={scheduleForm}
        onNewScheduleSubmit={onNewScheduleSubmit}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        navigate={navigate}
      />
    </Grid>
  )
}
