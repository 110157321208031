import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Button, Modal } from "@mui/material"

import { updateCheckOutLeaveScheduleRequest } from "../../../../api/employee/seatRequest"
import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { useCheckInOutSeat } from "../../../../models/employee/useCheckInOutSeat"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { useOpen } from "../../../../utils/isOpen"
import { ErrorText } from "../../../public/ErrorText"
import { WideCancelButton } from "../../../public/WideCancelButton"
import { WideReflectButton } from "../../../public/WideReflectButton"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

const CustomModalBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}))

const CustomTextColor = styled("span")(() => ({
  color: "#66CFBB",
  fontSize: "15px",
}))

const CustomSpan = styled("span")(() => ({
  fontSize: "13px",
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

interface Props {
  companyRelations: CompanyRelationsType
  checkInData: CheckInData
}

export const CheckOutMain = ({ checkInData }: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()

  const dateTimeJaMobileFormat = (now: Date) => {
    const dateString = now.toLocaleString()
    const date = new Date(dateString.replace(/-/g, "/"))
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const day = ("0" + date.getDate()).slice(-2)
    const hour = ("0" + date.getHours()).slice(-2)
    const min = ("0" + date.getMinutes()).slice(-2)

    return `${month}${translations.month}${day}${translations.day} ${hour}:${min}`
  }

  const { checkInOutSeat, updateCheckInOutSeat, errorMessages } =
    useCheckInOutSeat()
  const checkInOutOpen = useOpen()
  const isCheckIn = checkInOutSeat.check_in.is_check_in

  const [scheduleLeaveIsOpen, setScheduleLeaveIsOpen] = useState<boolean>(false)
  const scheduleLeaveIsClose = () => {
    setScheduleLeaveIsOpen(false)
  }
  const [currentCheckoutResource, setCurrentCheckoutResource] = useState<
    string | null
  >(null)

  const scheduleLeaveSchedule = async () => {
    if (!checkInOutSeat.check_in?.schedule?.id) return Promise.resolve()
    try {
      const response = await updateCheckInOutSeat({
        checked_in: !checkInOutSeat.check_in.is_check_in,
        schedule_id: checkInOutSeat.check_in.schedule.id,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const scheduleScheduleRescission = async () => {
    if (!checkInOutSeat.check_in?.schedule?.id) return Promise.resolve()
    const response = await updateCheckOutLeaveScheduleRequest({
      checked_in: !checkInOutSeat.check_in.is_check_in,
      schedule_id: checkInOutSeat.check_in.schedule.id,
    })
    return response
  }

  const handleUpdateCheckOutInSeat = async () => {
    // Seat checkout logic
    if (!checkInOutSeat.check_in?.schedule?.id) return

    setCurrentCheckoutResource("seat")
    if (checkInOutSeat.check_in.is_check_in) {
      setScheduleLeaveIsOpen(true)
      checkInOutOpen.close()
    } else {
      await updateCheckInOutSeat({
        checked_in: !checkInOutSeat.check_in.is_check_in,
        schedule_id: checkInOutSeat.check_in.schedule.id,
      })
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h5">
            {checkInData.branchName}
          </CustomTypography>
          <CustomTypography variant="h5">
            {checkInData.floorNumber}F
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h4">
            {translations.Desk}：{checkInData.seatName}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography>{translations.YouCheckOut}</CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <WideReflectButton
            label={translations.Checkout}
            handleClick={handleUpdateCheckOutInSeat}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
          >
            <Typography fontWeight="bold">{translations.Home}</Typography>
          </Button>
        </Grid>
      </Grid>
      <Modal open={scheduleLeaveIsOpen} onClose={scheduleLeaveIsClose}>
        <ModalBox>
          <CustomModalBox>
            {currentCheckoutResource === "seat" &&
              isCheckIn &&
              checkInOutSeat.check_in.schedule && (
                <CustomSpan>
                  {translations.CurrentDesk}{" "}
                  <CustomTextColor>
                    {dateTimeJaMobileFormat(
                      checkInOutSeat.check_in.schedule.end_time
                    )}
                  </CustomTextColor>
                  {translations.to}
                  <br />
                  {translations.Reserved}
                  <br />
                  {translations.WouldYouAgain}
                </CustomSpan>
              )}
          </CustomModalBox>
          <Box pb="1rem">
            {errorMessages.length !== 0 && (
              <ErrorMessageContainer textAlign="center">
                {ErrorText(errorMessages)}
              </ErrorMessageContainer>
            )}
          </Box>
          <CustomModalBox>
            <WideReflectButton
              label={translations.currentTime}
              handleClick={async () => {
                try {
                  if (scheduleLeaveIsOpen) {
                    await scheduleScheduleRescission()
                    window.location.reload()
                    localStorage.setItem(
                      "alertContent",
                      translations.SuccessUpdate
                    )
                  }
                } catch (error) {
                  localStorage.setItem(
                    "alertContent",
                    translations.CheckOutFailed
                  )
                }
              }}
              labelStyle={{ fontSize: "15px" }}
            />
          </CustomModalBox>
          <CustomModalBox>
            <WideCancelButton
              label={translations.UseAgain}
              handleClick={async () => {
                await scheduleLeaveSchedule()
                navigate("/employee/home")
                window.location.reload()
                localStorage.setItem("alertContent", translations.checkOut)
              }}
            />
          </CustomModalBox>
          <CustomModalBox>
            <WideCancelButton
              label={translations.Cancel}
              handleClick={scheduleLeaveIsClose}
            />
          </CustomModalBox>
        </ModalBox>
      </Modal>
    </Box>
  )
}
