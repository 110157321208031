import React from "react"

import { Grid } from "@mui/material"

import { CheckInLoginForm } from "../../../components/mobile/checkIn/CheckInLoginForm"
import { Loading } from "../../../components/public/Loading"
import { useCheckInLogin } from "../../../models/mobile/useCheckInAuth/login"

export const MobileCheckInLogin = () => {
  const authObject = useCheckInLogin()

  return (
    <Grid container justifyContent="center">
      {authObject.loading && (
        <Loading type="content" loading={authObject.loading} />
      )}
      <Grid item xs={10}>
        <CheckInLoginForm {...authObject} />
      </Grid>
    </Grid>
  )
}
