export type ScheduleFormResponseType = {
  outlook_event_id: string
  google_event_id: string
  schedule_id: any
  outlook_authenticated: boolean
  google_authenticated: boolean
  schedule_information_id: number
}

export interface ScheduleForm {
  schedule: {
    id: number
    schedule_title: string
    message_content: string
    schedule_date_type: number
    start_date: Date | null
    start_time: Date | null
    end_date: Date | null
    end_time: Date | null
    whole_day_flag: boolean
    weekly_or_monthly: EveryDateType
    is_secret: number
    weekly: {
      every_week: number
      days: boolean[]
    }
    monthly: {
      dayly?: {
        monthly: number
        day: number
      }
      dayOfWeekly?: {
        monthly: number
        weekly: number
        dayOfWeek: number
      }
    }
    share_schedule: ShareSchedule
    is_email: boolean
    is_confirm: boolean
  }
}

export type EveryDateType = typeof EveryDateType[keyof typeof EveryDateType]

const EveryDateType = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
} as const

export type ShareSchedule = {
  who_is_wheres: WhoIsWheres
  notification: Notification
}

export type WhoIsWhere = {
  userable: ScheduleFormUserable
  reservable: ScheduleFormReservable
}

export type WhoIsWheres = WhoIsWhere[]

export type UserableType = typeof UserableType[keyof typeof UserableType]

const UserableType = {
  EMPLOYEE: "Employee",
  GUEST: "Guest",
} as const

export type ScheduleFormUserable = {
  userable_id: number | undefined
  last_name: string | undefined
  first_name: string | undefined
  email: string | undefined
  userable_type: UserableType
  company_name: string | undefined
  should_send_guest_reception_notification_mail: boolean
}

export type ScheduleFormUserables = ScheduleFormUserable[]

export type ReservableType = typeof ReservableType[keyof typeof ReservableType]
export const ReservableType = {
  SEAT: "Seat",
  MEETINGROOM: "MeetingRoom",
} as const

export type ScheduleFormReservable = {
  reservable_id: number | undefined
  name: string | undefined
  reservable_type: ReservableType | undefined
}

export type ScheduleFormReservables = ScheduleFormReservable[]

export type Notification = typeof Notification[keyof typeof Notification]
const Notification = {
  AUTO: "auto",
  MANUAL: "manual",
} as const

export type MeetingRoom = {
  id: number
  meeting_room_name: string
  meeting_room_code: number
  capacity: number
  meeting_room_description: string
  meeting_room_image: string
  color_code: string
  appliances: { id: number; appliance_name: string }[]
  available_time: number
}

export type MeetingRooms = MeetingRoom[]

export type ScheduleFormLocationType = {
  navigateFrom: string
}
