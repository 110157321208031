const en = {
  eightCharacters: "Please enter at least 8 characters.",
  PleaseOneSymbol:
    "Please include at least one uppercase letter, one lowercase letter, one number, and one symbol.",
  Required: "Required",
  fiftyPassword: "Please enter a password of 50 characters or less.",
  PasswordNotMatch: "Password and Confirm Password do not match.",
  password: "Please enter your password in the correct format.",
  email: "Please enter your email.",
  CheckIn: "check in",
  Checkout: "check out",
  checkIn: "Success! You are checked in.",
  checkOut: "Success! You are checked out.",
  CheckInFailed: "Check-in failed.",
  CheckOutFailed: "Check-out failed.",
  update: "Your schedule has been successfully updated.",
  create: "Your schedule has been created.",
  TypingError: "There is a typing error, please try again",
  Katakana: "Use full-width Katakana.",
  UseHiragana: "Use Hiragana, Katakana, Kanji, or Alphabet.",
  favoriteAdd: "Your favorite team has been added",
  favoriteDelete: "Your favorite group has been deleted.",
  pleaseEnterTwo: "Please enter at least 2 characters.",
  fiftyEmployeeSearch: "Please enter no more than 50 characters.",
  pleaseFloor: "Please select a floor.",
  EmployeeNameRequired:
    "Employee name, location, floor, or business unit is required.",
  AlreadyDesk:
    "There is already a desk reserved at the same time, are you sure you want to update it?",
  NoData: "No data found",
  UserNotFound: "User not found",
  digitsCode: "Enter the 6 digits code",
  halfWidthNumbers: "Use half-width numbers",
  WouldYouAgain: "Would you like to use it again after checking out?",
  UseAgain: "Use it again",
  YouCheckIn: "Would you like to check in?",
  YouCheckOut: "Would you like to check out?",
  SuccessUpdate: "Success! The end time is updated.",
  ScheduleDeleted: "Schedule deleted!",
  Incorrect: "Incorrect date/time",
  YourScheduleAdded: " Your schedule is added.",
  ScheduleUpdated: "Schedule updated!",
  changePassword: "Failed to change password",
  selectMembers: "Please select members and add them to the team.",
  SeatAlreadyReserved: "This seat is already reserved.",
  ReservedSeat: "This seat is reserved.",
  PleaseFindAnotherSeat: "Please find another seat.",

  // エラーメッセージ以外
  FindLocation: "Find location",
  Search: "Search ",
  Desk: "Desk",
  RoomName: "Room Name",
  Name: "Name",
  CurrentDesk: "The current desk is",
  CurrentMeetingRoom: "The current meeting room is",
  Hour: "hh",
  Minutes: "mm",
  month: "/",
  day: "",
  ReservedUntil: "reserved until",
  to: "",
  Reserved: "reserved until",

  currentTime: "Update the end time to the current time",
  Cancel: "Cancel",
  DesksUsedBefore: "Desks you've used before",
  Subject: "SUBJECT",
  allDay: "All day",
  EmployeeName: "Employee name",
  Email: "E-mail",
  DeskStatus: "Desk status",
  DeleteRecurring: "Delete recurring schedule",
  ThisSchedule: "This schedule",
  FromThisSchedule: "From this schedule onward",
  AllSchedule: "All schedule",
  Delete: "Delete",
  Home: "Home",
  NoArea: "No authority to check in this area",
  anotherReserved:
    "You have another reserved desk, would you like to check in to this desk?",
  currentlyCheckedAnotherDesk:
    "You have currently checked in to another desk, would you like to reserve this desk?",
  wouldYouCheckIn: "Would you like to check in?",
  reservationInAnotherSpace:
    "You have a reservation in another space, would you like to check in to this space with the same member?",
  wouldYouCheckInSpace: "Would you like to check in to this space?",
}
export default en
