import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js"
import draftToHtmlPuri from "draftjs-to-html"

import React, { useContext, useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Controller, FormProvider } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import CircleCheckedFilled from "@mui/icons-material/CheckCircle"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import Divider from "@mui/material/Divider"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useUpdateShareScheduleReservable } from "../../../../hooks/employee/useUpdateShareScheduleReservable"
import { EmployeesType } from "../../../../models/employee/useSchedule/type"
import { useNewScheduleForm } from "../../../../models/employee/useSchedule/useScheduleForm"
import {
  ScheduleForm,
  WhoIsWheres,
} from "../../../../models/employee/useSchedule/useScheduleForm/type"
import { SchedulableMaxDateContext } from "../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../translations/employeeSchedule/employeeNewSchedule/en"
import jaTranslations from "../../../../translations/employeeSchedule/employeeNewSchedule/ja"
import { ReservableType } from "../../../../types/schedule"
import { useOpen } from "../../../../utils/isOpen"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"
import { EndDateComponent } from "./EndDateComponent"
import { EndDateTimeComponent } from "./EndDateTimeComponent"
import { SeatOrMeetingReservationComponent } from "./SeatOrMeetingReservationComponent"
import { ShareScheduleComponent } from "./ShareScheduleComponent"
import { StartDateComponent } from "./StartDateComponent"
import { StartDateTimeComponent } from "./StartDateTimeComponent"

type ScheduleParam = {
  scheduleForm: ScheduleForm | undefined
  employees: EmployeesType | undefined
  defaultDate: Date
  scheduleDayDefaultStartTime: number
  scheduleDayDefaultEndTime: number
  navigateFrom: string
  monthlySetting?: string
}

export interface ValidEndDateProps {
  validEndDateTime: string
  setValidEndDateTime: React.Dispatch<React.SetStateAction<string>>
  validEndDate: string
  setValidEndDate: React.Dispatch<React.SetStateAction<string>>
  setDateError: React.Dispatch<React.SetStateAction<boolean>>
  dateError: boolean
  defaultDate: Date
  scheduleDayDefaultStartTime: number
  scheduleDayDefaultEndTime: number
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  checkMeetingOrSeat: (whoIsWheres: WhoIsWheres) => boolean
}

// 仮データ(スケジュール画面からpropsにて受け取る)

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

const RepeatAreaTextField = styled(TextField)({
  width: "60px",
  maxWidth: "60px",
  minWidth: "60px",
  marginRight: "1rem",
  backgroundColor: "#fff",
  "& .MuiInputBase-input": {
    padding: "0",
    height: "2rem",
    textAlign: "center",
    backgroundColor: "rgba(112,112,112,0.05)",
  },
})

const checkMeetingOrSeat = (whoIsWheres: WhoIsWheres) => {
  const reservableMeetingOrSeat = whoIsWheres.filter((whoIsWhere) => {
    return whoIsWhere.reservable.reservable_id
  })

  return reservableMeetingOrSeat.length !== 0
}

export const CalendarNewComponent: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const scheduleParam = location.state as ScheduleParam
  const {
    methods,
    errorMessages,
    onNewScheduleSubmit,
    setErrorMessages,
    validEndDateTime,
    validEndDate,
    setValidEndDateTime,
    setValidEndDate,
  } = useNewScheduleForm(scheduleParam.employees, scheduleParam.scheduleForm)

  const { schedulableMonthes } = useContext(SchedulableMaxDateContext)

  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [repeatSetting, setRepeatSetting] = useState<"weekly" | "monthly">(
    "weekly"
  )
  const [monthlySetting, setMonthlySetting] = useState<
    "day" | "dayOfWeek" | undefined
  >("day")
  const [wholeDay, setWholeDay] = useState(false)

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = methods

  const [is_startDate, setIsStartDate] = useState<number>(0)
  const [is_endDate, setIsEndDate] = useState<number>(0)
  const [is_startHours, setIsStartHours] = useState<number>(0)
  const [is_endHours, setIsEndHours] = useState<number>(0)
  const initData = convertFromHTML(getValues("schedule.message_content"))
  const initState = ContentState.createFromBlockArray(
    initData.contentBlocks,
    initData.entityMap
  )
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(initState)
  )

  const startDate = getValues("schedule.start_date")
  const endDate = getValues("schedule.end_date")
  const startTime = getValues("schedule.start_time")
  const endTime = getValues("schedule.end_time")
  const whoIsWheres = getValues("schedule.share_schedule.who_is_wheres")

  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  function getErrorKeyFromMessage(errorMessage: string): string {
    switch (errorMessage) {
      case "すでに同じ時間に席予約がありますが、本当に更新しますか？":
        return "backend_specific_error"
      case "こちらの席は他のユーザーが予約しています":
        return "seat_already_reserved"
      case "こちらの会議室は他のユーザーが予約しています":
        return "room_already_reserved"
      default:
        return errorMessage // 既存のエラーメッセージをそのまま返す
    }
  }

  const errorKey = getErrorKeyFromMessage(errorMessages[0])
  let displayedErrorMessage = errorMessages[0]

  // 以下の部分を変更して、エラーメッセージのキーに基づいてtranslationsから翻訳を取得する
  displayedErrorMessage =
    (translations as any)[errorKey] || displayedErrorMessage

  const days: string[] = [
    translations["Sunday"],
    translations["Monday"],
    translations["Tuesday"],
    translations["Wednesday"],
    translations["Thursday"],
    translations["Friday"],
    translations["Saturday"],
  ]

  const weeks: string[] = [
    translations["first"],
    translations["second"],
    translations["third"],
    translations["fourth"],
    translations["last"],
  ]

  // 手動で日付・時刻を編集する際にエラーにならないようにする
  useEffect(() => {
    if (typeof startDate === "object" && startDate && "getDate" in startDate) {
      setIsStartDate(getValues("schedule.start_date")!.getDate())
    }
    if (typeof endDate === "object" && endDate && "getDate" in endDate) {
      setIsEndDate(getValues("schedule.end_date")!.getDate())
    }
    if (typeof startTime === "object" && startTime && "getHours" in startTime) {
      setIsStartHours(getValues("schedule.start_time")!.getHours())
    }
    if (typeof endTime === "object" && endTime && "getHours" in endTime) {
      setIsEndHours(getValues("schedule.end_time")!.getHours())
    }
  }, [startDate, endDate, startTime, endTime])

  useEffect(() => {
    if (scheduleParam.monthlySetting) {
      setMonthlySetting(scheduleParam.monthlySetting as "day" | "dayOfWeek")
    }
    setRepeatSetting(getValues("schedule.weekly_or_monthly"))
  }, [])

  useEffect(() => {
    const errorKey = getErrorKeyFromMessage(errorMessages[0])
    setIsDisable(
      errorKey === "seat_already_reserved" ||
        errorKey === "room_already_reserved"
    )
  }, [errorMessages])

  const { isOpen, open, close } = useOpen()
  const [dateError, setDateError] = useState<boolean>(true)

  const handleWholeDayChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setWholeDay(checked)
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.AllDayReset])
    }

    if (validEndDateTime == "#d32f2f" && checked) {
      setValue("schedule.start_time", new Date())
      setValue("schedule.end_time", new Date())
      setValidEndDateTime("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    } else if (checked) {
      setDateError(true)
    }

    setValue("schedule.whole_day_flag", checked)
  }

  // 予定の開始日をlocalStorageに保存（画面遷移後に該当日付の予定を表示するため）
  const handleSetTargetDate = () => {
    const startTime = watch("schedule.start_date")
    if (startTime) {
      window.localStorage.setItem("targetDay", startTime.getTime().toString())
    }
  }

  const selectedDay = () => {
    const scheduleValue = getValues("schedule.start_date")
    if (!scheduleValue) {
      return null
    }
    const selectDay = getValues("schedule.monthly.dayly.day")
    const selectWeekday = scheduleValue.getDay()

    let weekNumber = 0
    for (let day = 1; day <= selectDay; day++) {
      if (
        new Date(
          scheduleValue.getFullYear(),
          scheduleValue.getMonth(),
          day
        ).getDay() === selectWeekday
      ) {
        weekNumber++
      }
    }
    return weeks[weekNumber - 1]
  }
  const selectedWeekDay = () => {
    return days[getValues("schedule.monthly.dayOfWeekly.dayOfWeek")]
  }
  return (
    <>
      <Box>
        {errorMessages.length !== 0 && (
          <ErrorMessageContainer textAlign="center">
            {ErrorText([displayedErrorMessage])}
          </ErrorMessageContainer>
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <FormProvider {...methods}>
          <Box display="flex" alignItems="center" height="100%" width="100%">
            <Grid
              item
              md={6}
              sm={6}
              height="570px"
              width="55%"
              marginRight="15px"
            >
              <Controller
                control={control}
                name="schedule.schedule_title"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.schedule?.schedule_title}
                    helperText={errors.schedule?.schedule_title?.message}
                    required
                    id="schedule_title"
                    name="schedule_title"
                    label={translations.scheduleTitle}
                    fullWidth
                    InputProps={{
                      sx: {
                        "& .MuiOutlinedInput-input": {
                          padding: "12px 15px",
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                      },
                    }}
                  />
                )}
              />
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop="2rem"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={language === "ja" ? ja : enUS}
                >
                  <Box display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/active-calendar.svg`}
                      style={{
                        height: "20px",
                        marginRight: "8px",
                      }}
                    />
                  </Box>
                  <StartDateComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={scheduleParam.defaultDate}
                    scheduleDayDefaultStartTime={
                      scheduleParam.scheduleDayDefaultStartTime
                    }
                    scheduleDayDefaultEndTime={
                      scheduleParam.scheduleDayDefaultEndTime
                    }
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                  <StartDateTimeComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={scheduleParam.defaultDate}
                    scheduleDayDefaultStartTime={
                      scheduleParam.scheduleDayDefaultStartTime
                    }
                    scheduleDayDefaultEndTime={
                      scheduleParam.scheduleDayDefaultEndTime
                    }
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                  <Grid marginLeft="1rem" marginRight="1rem">
                    ~
                  </Grid>
                  <EndDateTimeComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={scheduleParam.defaultDate}
                    scheduleDayDefaultStartTime={
                      scheduleParam.scheduleDayDefaultStartTime
                    }
                    scheduleDayDefaultEndTime={
                      scheduleParam.scheduleDayDefaultEndTime
                    }
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                  <EndDateComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={scheduleParam.defaultDate}
                    scheduleDayDefaultStartTime={
                      scheduleParam.scheduleDayDefaultStartTime
                    }
                    scheduleDayDefaultEndTime={
                      scheduleParam.scheduleDayDefaultEndTime
                    }
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                </LocalizationProvider>
              </Grid>
              <Box display="flex">
                <Controller
                  control={control}
                  name="schedule.whole_day_flag"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      name="whole_day_flag"
                      id="whole_day_flag"
                      sx={{ padding: "10px 0", marginLeft: "2rem" }}
                      checked={getValues("schedule.whole_day_flag")}
                      onChange={handleWholeDayChange}
                      icon={
                        <CircleUnchecked
                          style={{ color: "22BA9D", fontSize: "20px" }}
                        />
                      }
                      checkedIcon={
                        <CircleCheckedFilled style={{ fontSize: "20px" }} />
                      }
                    />
                  )}
                />
                <Box
                  marginLeft={1}
                  display="flex"
                  alignItems="center"
                  fontSize="12px"
                  fontFamily="Noto Sans"
                >
                  {translations.Allday}
                </Box>
              </Box>

              <Box display="flex">
                <Controller
                  control={control}
                  name="schedule.schedule_date_type"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={Boolean(watch("schedule.schedule_date_type"))}
                      onChange={(_, checked) => {
                        if (
                          checkMeetingOrSeat(
                            getValues("schedule.share_schedule.who_is_wheres")
                          )
                        ) {
                          return setErrorMessages([translations.RepeatReset])
                        }
                        if (checked) {
                          const start_date = getValues("schedule.start_date")
                          if (start_date) {
                            const wDay = start_date.getDay()
                            getValues("schedule.weekly.days")[wDay] = true
                            setMonthlySetting("day")
                          }
                        } else {
                          setValue(
                            "schedule.weekly.days",
                            new Array(7).fill(false)
                          )
                          setMonthlySetting(undefined)
                        }
                        setValue("schedule.schedule_date_type", Number(checked))
                      }}
                      name="schedule_date_type"
                      id="schedule_date_type"
                      sx={{ padding: "10px 0", marginLeft: "2rem" }}
                      icon={
                        <CircleUnchecked
                          style={{ color: "22BA9D", fontSize: "20px" }}
                        />
                      }
                      checkedIcon={
                        <CircleCheckedFilled style={{ fontSize: "20px" }} />
                      }
                    />
                  )}
                />

                <Box
                  marginLeft={1}
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: "#22BA9D",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    fontFamily: "Noto Sans",
                  }}
                >
                  {translations.RepeatSettings}
                </Box>
              </Box>
              <Box marginLeft="2rem" display="flex">
                <FormControl
                  sx={{ width: "90px", minWidth: "90px", marginRight: "1rem" }}
                >
                  <Controller
                    control={control}
                    name="schedule.weekly_or_monthly"
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="weekly_or_monthly"
                        name="weekly_or_monthly"
                        sx={{
                          height: "2rem",
                          width: "90px",
                          backgroundColor: "rgba(112,112,112,0.05)",
                          "& .MuiSelect-outlined": {
                            fontSize: "12px",
                            fontFamily: "Noto Sans",
                            padding: "0 0 0 8px",
                          },
                        }}
                        defaultValue={"weekly"}
                        IconComponent={GreenArrowIcon}
                        onChange={(e) => {
                          if (
                            checkMeetingOrSeat(
                              getValues("schedule.share_schedule.who_is_wheres")
                            )
                          ) {
                            return setErrorMessages([translations.RepeatReset])
                          }
                          if (
                            e.target.value === "weekly" ||
                            e.target.value === "monthly"
                          ) {
                            field.onChange(e.target.value)
                            setRepeatSetting(e.target.value)
                            setValue(
                              "schedule.weekly_or_monthly",
                              e.target.value
                            )
                          }
                        }}
                      >
                        <MenuItem sx={{ fontSize: "12px" }} value={"weekly"}>
                          {translations.Weekly}
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "12px" }} value={"monthly"}>
                          {translations.Monthly}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {repeatSetting === "weekly" && (
                  <>
                    <Controller
                      control={control}
                      name="schedule.weekly.every_week"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type={"number"}
                          InputProps={{
                            inputProps: { min: 1, max: 4 },
                          }}
                          name="every_week"
                          error={!!errors.schedule?.weekly?.every_week}
                          helperText={
                            errors.schedule?.weekly?.every_week?.message
                          }
                          sx={{
                            minWidth: "60px",
                            width: "60px",
                            marginRight: "1rem",
                            backgroundColor: "#fff",
                            "& .MuiInputBase-input": {
                              fontSize: "12px",
                              fontFamily: "Noto Sans",
                              padding: "0",
                              height: "2rem",
                              textAlign: "center",
                              backgroundColor: "rgba(112,112,112,0.05)",
                            },
                          }}
                          onChange={(e) => {
                            if (!watch("schedule.schedule_date_type")) {
                              return
                            }
                            if (
                              checkMeetingOrSeat(
                                getValues(
                                  "schedule.share_schedule.who_is_wheres"
                                )
                              )
                            ) {
                              return setErrorMessages([
                                translations.RepeatReset,
                              ])
                            }
                            setValue(
                              "schedule.weekly.every_week",
                              Number(e.target.value)
                            )
                          }}
                        />
                      )}
                    />
                    <Box
                      sx={{
                        minWidth: "50px",
                        marginRight: "1rem",
                        height: "2rem",
                        lineHeight: "2rem",
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {translations.Weekly}
                    </Box>
                    <Grid container display="flex" sx={{ height: "5rem" }}>
                      {days.map((day, index) => (
                        <Grid item sm={3} key={index}>
                          <Box display="flex">
                            <Checkbox
                              name="days"
                              checked={watch("schedule.weekly.days")[index]}
                              onChange={(_, checked) => {
                                if (
                                  checkMeetingOrSeat(
                                    getValues(
                                      "schedule.share_schedule.who_is_wheres"
                                    )
                                  )
                                ) {
                                  return setErrorMessages([
                                    translations.RepeatReset,
                                  ])
                                }
                                if (!watch("schedule.schedule_date_type")) {
                                  return
                                }
                                if (
                                  !checked &&
                                  getValues("schedule.weekly.days").filter(
                                    (day) => day
                                  ).length === 1
                                ) {
                                  return
                                }
                                const days: boolean[] = getValues(
                                  "schedule.weekly.days"
                                )
                                days.splice(index, 1, checked)
                                setValue("schedule.weekly.days", days)
                              }}
                              sx={{ padding: "0" }}
                              icon={
                                <CircleUnchecked
                                  style={{ color: "22BA9D", fontSize: "20px" }}
                                />
                              }
                              checkedIcon={
                                <CircleCheckedFilled
                                  style={{ fontSize: "20px" }}
                                />
                              }
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              fontSize="12px"
                              fontFamily="Noto Sans"
                            >
                              {day}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                {repeatSetting === "monthly" && (
                  <>
                    <Stack spacing={2}>
                      <Box display="flex">
                        <Box display="flex">
                          <Checkbox
                            name="monthly_day"
                            checked={monthlySetting === "day"}
                            onChange={() => {
                              if (!watch("schedule.schedule_date_type")) {
                                return
                              }
                              setMonthlySetting("day")
                            }}
                            sx={{ padding: "0" }}
                            icon={
                              <CircleUnchecked
                                style={{ color: "22BA9D", fontSize: "20px" }}
                              />
                            }
                            checkedIcon={
                              <CircleCheckedFilled sx={{ fontSize: "20px" }} />
                            }
                          />

                          {language === "ja" ? (
                            <>
                              {/* 月ごとの繰り返し予定_日_日本語 */}
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "50px",
                                }}
                              >
                                {translations.Day}
                              </Box>
                              <Controller
                                control={control}
                                name="schedule.monthly.dayly.monthly"
                                render={({ field }) => (
                                  <RepeatAreaTextField
                                    {...field}
                                    type={"number"}
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                        max: schedulableMonthes,
                                      },
                                    }}
                                    name="monthly"
                                    error={
                                      !!errors.schedule?.monthly?.dayly?.monthly
                                    }
                                    helperText={
                                      errors.schedule?.monthly?.dayly?.monthly
                                        ?.message
                                    }
                                    onChange={(e) => {
                                      if (
                                        !watch("schedule.schedule_date_type")
                                      ) {
                                        return
                                      }
                                      const number = parseInt(e.target.value)
                                      if (
                                        1 <= number &&
                                        number <= schedulableMonthes
                                      ) {
                                        setValue(
                                          "schedule.monthly.dayly.monthly",
                                          parseInt(e.target.value)
                                        )
                                      }
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: "12px",
                                        fontFamily: "Noto Sans",
                                      },
                                    }}
                                  />
                                )}
                              />
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "100px",
                                  marginRight: "1rem",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {translations.everyMonths}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  width: "30%",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {getValues("schedule.monthly.dayly.day")}
                                {translations.day}
                              </Box>
                              {/* 英語 */}
                            </>
                          ) : (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "50px",
                                }}
                              >
                                {translations.Day}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "50px",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {getValues("schedule.monthly.dayly.day")}
                                {translations.day}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "30px",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {translations.of}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "60px",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {translations.every}
                              </Box>
                              <Controller
                                control={control}
                                name="schedule.monthly.dayly.monthly"
                                render={({ field }) => (
                                  <RepeatAreaTextField
                                    {...field}
                                    type={"number"}
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                        max: schedulableMonthes,
                                      },
                                    }}
                                    name="monthly"
                                    error={
                                      !!errors.schedule?.monthly?.dayly?.monthly
                                    }
                                    helperText={
                                      errors.schedule?.monthly?.dayly?.monthly
                                        ?.message
                                    }
                                    onChange={(e) => {
                                      if (
                                        !watch("schedule.schedule_date_type")
                                      ) {
                                        return
                                      }
                                      const number = parseInt(e.target.value)
                                      if (
                                        1 <= number &&
                                        number <= schedulableMonthes
                                      ) {
                                        setValue(
                                          "schedule.monthly.dayly.monthly",
                                          parseInt(e.target.value)
                                        )
                                      }
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontSize: "12px",
                                        fontFamily: "Noto Sans",
                                      },
                                    }}
                                  />
                                )}
                              />
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Noto Sans",
                                  minWidth: "100px",
                                  marginRight: "1rem",
                                  height: "2rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                {translations.months}
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box display="flex">
                          <Box display="flex">
                            <Checkbox
                              name="monthly_dayOfWeek"
                              checked={monthlySetting === "dayOfWeek"}
                              onChange={() => {
                                if (!watch("schedule.schedule_date_type")) {
                                  return
                                }
                                setMonthlySetting("dayOfWeek")
                              }}
                              sx={{ padding: "0" }}
                              icon={
                                <CircleUnchecked
                                  style={{ color: "#22BA9D", fontSize: "20px" }}
                                />
                              }
                              checkedIcon={
                                <CircleCheckedFilled
                                  sx={{ fontSize: "20px" }}
                                />
                              }
                            />
                            {/* 月ごとの繰り返し予定_曜日_日本語 */}
                            {language === "ja" ? (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "50px",
                                    marginRight: "20px",
                                  }}
                                >
                                  {translations.DayOfWeek}
                                </Box>
                                <Controller
                                  control={control}
                                  name="schedule.monthly.dayOfWeekly.monthly"
                                  render={({ field }) => (
                                    <RepeatAreaTextField
                                      {...field}
                                      name="monthly"
                                      type={"number"}
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: schedulableMonthes,
                                        },
                                      }}
                                      error={
                                        !!errors.schedule?.monthly?.dayOfWeekly
                                          ?.monthly
                                      }
                                      helperText={
                                        errors.schedule?.monthly?.dayOfWeekly
                                          ?.monthly?.message
                                      }
                                      onChange={(e) => {
                                        if (
                                          !watch("schedule.schedule_date_type")
                                        ) {
                                          return
                                        }
                                        const number = parseInt(e.target.value)
                                        if (
                                          1 <= number &&
                                          number <= schedulableMonthes
                                        ) {
                                          setValue(
                                            "schedule.monthly.dayOfWeekly.monthly",
                                            parseInt(e.target.value)
                                          )
                                        }
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "12px",
                                          fontFamily: "Noto Sans",
                                        },
                                      }}
                                    />
                                  )}
                                />
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "90px",
                                    marginRight: "1rem",
                                    height: "2rem",
                                    lineHeight: "2rem",
                                  }}
                                >
                                  {translations.everyMonths}
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="12px"
                                  fontFamily="Noto Sans"
                                >
                                  {selectedDay()}
                                  {selectedWeekDay()}
                                </Box>
                                {/* 英語 */}
                              </>
                            ) : (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "50px",
                                    marginRight: "20px",
                                  }}
                                >
                                  {translations.The}
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{ marginRight: "1rem" }}
                                >
                                  {selectedDay()} {selectedWeekDay()}
                                </Box>
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "30px",
                                    height: "2rem",
                                    lineHeight: "2rem",
                                  }}
                                >
                                  {translations.of}
                                </Box>
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "60px",
                                    height: "2rem",
                                    lineHeight: "2rem",
                                  }}
                                >
                                  {translations.every}
                                </Box>
                                <Controller
                                  control={control}
                                  name="schedule.monthly.dayOfWeekly.monthly"
                                  render={({ field }) => (
                                    <RepeatAreaTextField
                                      {...field}
                                      name="monthly"
                                      type={"number"}
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: schedulableMonthes,
                                        },
                                      }}
                                      error={
                                        !!errors.schedule?.monthly?.dayOfWeekly
                                          ?.monthly
                                      }
                                      helperText={
                                        errors.schedule?.monthly?.dayOfWeekly
                                          ?.monthly?.message
                                      }
                                      onChange={(e) => {
                                        if (
                                          !watch("schedule.schedule_date_type")
                                        ) {
                                          return
                                        }
                                        const number = parseInt(e.target.value)
                                        if (
                                          1 <= number &&
                                          number <= schedulableMonthes
                                        ) {
                                          setValue(
                                            "schedule.monthly.dayOfWeekly.monthly",
                                            parseInt(e.target.value)
                                          )
                                        }
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "12px",
                                          fontFamily: "Noto Sans",
                                        },
                                      }}
                                    />
                                  )}
                                />
                                <Box
                                  sx={{
                                    fontSize: "12px",
                                    fontFamily: "Noto Sans",
                                    minWidth: "90px",
                                    marginRight: "1rem",
                                    height: "2rem",
                                    lineHeight: "2rem",
                                  }}
                                >
                                  {translations.months}
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </>
                )}
              </Box>
              <Box display="flex" marginTop={2}>
                <Box display="flex" alignItems="center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/key.svg`}
                    style={{
                      height: "20px",
                      color: "#22BA9D",
                    }}
                  />
                </Box>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="schedule.is_secret"
                    render={({ field }) => (
                      <Select
                        {...field}
                        name="is_secret"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Noto Sans",
                          height: "2rem",
                          marginLeft: "1rem",
                          width: "30%",
                          backgroundColor: "rgba(112,112,112,0.05)",
                          "& .MuiSelect-outlined": {
                            padding: "0 0 0 8px",
                          },
                        }}
                        onChange={(e) => {
                          field.onChange(e)
                        }}
                        defaultValue={0}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem sx={{ fontSize: "12px" }} value={0}>
                          {translations.Public}
                        </MenuItem>
                        <MenuItem sx={{ fontSize: "12px" }} value={1}>
                          {translations.Private}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              <Box display="flex" marginTop={3} marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <MailOutlineIcon
                    style={{
                      color: "22BA9D",
                      fontSize: "20px",
                      marginLeft: "-2px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    color: "#22BA9D",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Noto Sans",
                    marginLeft: "1rem",
                  }}
                >
                  {translations.Description}
                </Box>
              </Box>
              <Editor
                editorStyle={{
                  height: "145px",
                  overflow: "auto",
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                }}
                toolbar={{
                  // options: ["inline"],
                  options: ["inline", "list"],
                  inline: {
                    options: ["bold", "underline", "italic"],
                  },
                  list: { options: ["ordered", "unordered"] },
                }}
                localization={{ locale: "ja" }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                onChange={() =>
                  setValue(
                    "schedule.message_content",
                    draftToHtmlPuri(
                      convertToRaw(editorState.getCurrentContent())
                    )
                  )
                }
              />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={5.35} sm={5.35} flexGrow="1" margin="70px 0 0 15px">
              <ShareScheduleComponent />
              <Divider flexItem sx={{ margin: "15px 0" }} />
              <SeatOrMeetingReservationComponent
                monthlySetting={monthlySetting}
                isWholeDay={wholeDay}
                whoIsWheres={whoIsWheres}
              />
              <Grid container spacing={2} marginTop="15px">
                <Grid item sm={6} md={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{ borderRadius: "30px", fontSize: "16px" }}
                    onClick={() => {
                      scheduleParam.navigateFrom
                        ? navigate(scheduleParam.navigateFrom, {
                            state: {
                              employees: scheduleParam.employees,
                              navigateFrom: window.location.pathname,
                            },
                          })
                        : navigate("/employee/layouts")
                    }}
                  >
                    {translations.Cancel}
                  </Button>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ borderRadius: "30px", fontSize: "16px" }}
                    disabled={
                      !isValid ||
                      validEndDateTime == "#d32f2f" ||
                      validEndDate == "#d32f2f"
                    }
                    onClick={() => {
                      if (
                        startDate
                          ? startDate <
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                          : false
                      ) {
                        setErrorMessages([translations.SchedulePast])
                        setValidEndDate("#d32f2f")
                      } else if (
                        is_startDate != is_endDate &&
                        Boolean(watch("schedule.schedule_date_type")) == false
                      ) {
                        {
                          setErrorMessages([translations.SpanDays])
                          setValidEndDate("#d32f2f")
                        }
                      } else if (
                        (0 <= is_startHours && is_startHours < 6) ||
                        (0 <= is_endHours && is_endHours < 6)
                      ) {
                        {
                          setErrorMessages([translations.OutsideBusinessHours])
                          setValidEndDateTime("#d32f2f")
                        }
                      } else {
                        {
                          setErrorMessages([])
                          open()
                        }
                      }
                    }}
                  >
                    {translations.ConfirmReservation}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Modal open={isOpen} onClose={close}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "40%",
                  backgroundColor: "#fff",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "10px",
                }}
              >
                <Box padding="30px 30px 20px">
                  {errorKey !== "seat_already_reserved" &&
                    errorKey !== "room_already_reserved" && (
                      <Typography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          marginBottom: "2rem",
                        }}
                        variant="h6"
                      >
                        {/* 予約を確定しますか？ */}
                        {translations.WouldYouConfirmReservation}
                      </Typography>
                    )}

                  <Box display="flex" justifyContent="center">
                    <CancelButton
                      label={translations.edit}
                      handleClick={close}
                    />
                    <Box width="20px" /> {/* ここで10pxのスペースを作成 */}
                    <ReflectButton
                      // label="確定する"
                      label={translations.Confirm}
                      disabled={isDisable}
                      handleClick={async () => {
                        setIsDisable(true)
                        if (monthlySetting === "day") {
                          setValue("schedule.monthly.dayOfWeekly", undefined)
                        } else if (monthlySetting === "dayOfWeek") {
                          setValue("schedule.monthly.dayly", undefined)
                        }
                        handleSetTargetDate()
                        const whoIsWheres = getValues(
                          "schedule.share_schedule.who_is_wheres"
                        )
                        setValue(
                          "schedule.share_schedule.who_is_wheres",
                          whoIsWheres[0].reservable.reservable_type !==
                            ("MeetingRoom" as ReservableType)
                            ? whoIsWheres
                            : useUpdateShareScheduleReservable(whoIsWheres)
                        )
                        setValue("schedule.is_email", true)
                        await handleSubmit(onNewScheduleSubmit)()
                        setValue("schedule.is_confirm", true)
                        setIsDisable(false)
                      }}
                    />
                  </Box>

                  {/* エラーメッセージはここに移動しました */}
                  {errorMessages.length !== 0 && (
                    <ErrorMessageContainer
                      sx={{
                        marginTop: "1.3rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {ErrorText([displayedErrorMessage])}
                    </ErrorMessageContainer>
                  )}
                </Box>
              </Box>
            </Modal>
          </Box>
        </FormProvider>
      </Box>
    </>
  )
}
