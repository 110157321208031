const ja = {
  WelcomeBack: "おかえりなさい！",
  PleaseLoginAccount: "アカウントにログインしてください",
  EmailAddress: "メールアドレス（必須）",
  Password: "パスワード（必須)",
  Login: "ログイン",
  ForgotPassword: "パスワードをお忘れの方はこちら",
  LoginFailed: "ログインに失敗しました",
}
export default ja
