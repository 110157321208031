import React from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Typography, styled, Button, Box } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

interface Props {
  companyRelations: CompanyRelationsType
  checkInData: CheckInData
}

// チェックイン完了画面
export const CheckInCompleted = ({ checkInData }: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  // const navigate = useNavigate()

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
        p: 4,
        overflow: "auto",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h5">
            {checkInData.branchName}
          </CustomTypography>
          <CustomTypography variant="h5">
            {checkInData.floorNumber}F
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h4">
            {translations.Desk}：{checkInData.seatName}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography>{translations.checkIn}</CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
          >
            <Typography fontWeight="bold">{translations.Home}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
