import awsmobile from "../../aws-exports"

export const redirectToSAMLLogin = () => {
  const clientId = awsmobile.aws_user_pools_web_client_id // CognitoのアプリクライアントID
  const redirectUri = awsmobile.oauth.redirectSignIn // フロントエンドのリダイレクト先
  const domain = awsmobile.oauth.domain

  const loginUrl = `https://${domain}/authorize?identity_provider=azuread&response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}`

  window.location.href = loginUrl
}
