const ja = {
  checkInOut: "座席チェックイン/アウト",
  latest: "直近の出社予定",
  seatStatus: "現在の席状況",
  NewMessage: "新着メッセージ",
  Subject: "件名",
  From: "差出人",
  Repeat: "リピート",
  Location: "拠点",
  dates: "日付",
  TermsService: "利用規約",
  and: "と",
  PrivacyPolicy: "プライバシーポリシー",
  everyWeek: "毎週",
  everyWeeks: "週ごとの",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  week: "曜日",
  date_format: "yyyy年MM月dd日",
}
export default ja
