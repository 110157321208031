import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { SelectChangeEvent } from "@mui/material"

import {
  checkInRequest,
  fetchCheckInStatusRequest,
  instantCheckInRequest,
} from "../../../../api/employee/scheduleRequest"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { fiveIntervalMinutes } from "../../../../utils/hours"
import { roundDownMinutes, roundUpMinutes } from "../../../../utils/time"
import { ReservableType } from "../../../company/useQrCode/type"
import { useCheckInOutMeetingRoom } from "../../../employee/useCheckInOutMeetingRoom"
import { CompanyRelationsType } from "../../../public/useCompanyRelations/type"

export const useScheduleCheckIn = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [branchName, setBranchName] = useState<string>("")
  const [floorId, setFloorId] = useState<number>(0)
  const { currentUser } = useContext(AuthContext)
  const [floorNumber, setFloorNumber] = useState<string>("")
  const [checkInType, setCheckInType] = useState<ReservableType>("Seat")
  const [checkInTarget, setCheckInTarget] = useState<string>("")
  const [targetId, setTargetId] = useState<number>(0)
  const [notReservedModalMessage, setNotReservedModalMessage] =
    useState<string>("")
  const [notReserved, setNotReserved] = useState<boolean>(false)
  const [reservationHour, setReservationHour] = useState<number>(-1) // プルダウンの未選択状態を-1で定義
  const [reservationMinutes, setReservationMinutes] = useState<number>(-1)
  const [reservationModalHours, setReservationModalHours] = useState<number[]>(
    []
  )
  const [reservationModalMinutes, setReservationModalMinutes] =
    useState<number[]>(fiveIntervalMinutes)
  const [nextReservation, setNextReservation] = useState<Date | null>(null)
  const [checkInError, setCheckInError] = useState<boolean>(false)
  const [hasAnotherSchedule, setHasAnotherSchedule] = useState<boolean>(false)
  const [hasAnotherCheckIn, setHasAnotherCheckIn] = useState<boolean>(false)
  const [checkInErrorMessage, setCheckInErrorMessage] = useState<string>("")
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [checkInStatus, setCheckInStatus] = useState<boolean>(true)
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)
  const { fetchCheckInMeetingRoomName } = useCheckInOutMeetingRoom()
  const [availableTime, setAvailableTime] = useState<number | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (checkInStatus == false) setDataLoading(false)
  }, [checkInStatus])

  // クエリパラメータから各種stateを設定する
  const setCheckInStateFromQueryString = async (
    companyRelations: CompanyRelationsType
  ) => {
    let floor_id = 0
    // NOTE:クエリパラメータのフロア(floor_id)を持つ支店がユーザーが所属する企業にあるかどうかを確認する
    const isFoundBranch = companyRelations.branches.some((branch) => {
      const isFoundFloor = branch.floors.some((floor) => {
        if (floor.id === Number(queryParams.get("floor_id"))) {
          floor_id = floor.id
          setFloorId(floor.id)
          setBranchName(branch.branch_name)
          setFloorNumber(floor.floor_number)
          return true
        }
        return false
      })
      return isFoundFloor
    })

    // NOTE: 従業員が所属企業外の席情報を取得しないようにホームにリダイレクトさせる
    if (companyRelations.id > 0 && !isFoundBranch) {
      navigate("/mobile/home")
      return
    }

    setCheckInType(queryParams.get("type") as ReservableType)
    setTargetId(Number(queryParams.get("id")))
    if (checkInType === "Seat") {
      setCheckInTarget(queryParams.get("name") as string)
    } else {
      const meetingRoomName = await fetchCheckInMeetingRoomName(
        Number(queryParams.get("id"))
      )
      if (meetingRoomName) {
        setCheckInTarget(meetingRoomName) //会議室名は日本語の場合があり、URLに日本語を入れないようにDBから取得する
      }
    }
    if (currentUser) {
      if (location.pathname === "/mobile/check-in/completed") {
        setCheckInStatus(false)
      } else {
        checkInStatusJudgement(floor_id)
      }
    }
  }

  /** 今その席/会議室を自分がチェックインしているか否かを判断する */
  const checkInStatusJudgement = async (floor_id: number) => {
    try {
      const { data, error } = await fetchCheckInStatusRequest({
        reservable_type: checkInType,
        reservable_id: targetId,
        userable_type: "Employee",
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(
          "チェックイン状態の取得に失敗しました。\n画面を再読み込みしてください"
        )
      }

      if (data && targetId) {
        setAvailableTime(data.available_time)
        if (data.check_in_status) {
          if (floor_id && checkInType && checkInTarget) {
            navigate(
              `/mobile/check-out?floor_id=${floor_id}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          }
        } else {
          setCheckInStatus(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // チェックインボタンを押した時の挙動
  const checkIn = async () => {
    try {
      const { data, error } = await checkInRequest({
        reservable_id: targetId,
        reservable_type: checkInType,
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(translations.NoArea)
      }

      if (data) {
        if (data.check_in_completed) {
          navigate(
            `/mobile/check-in/completed?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
          )
        } else {
          if (data.already_reserved) {
            navigate(
              `/mobile/check-in/already-reserved?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else if (data.is_reservation_seat) {
            navigate(
              `/mobile/check-in/reservation-seat?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else if (data.is_checked_in_now === "this_seat") {
            navigate(
              `/mobile/check-in/already-checked-in?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else {
            handleReservationTimePullDownSet(data.next_reservation_time)
            setNotReserved(true)
            setNotReservedModalMessage(
              notReservedModalText(
                data.has_another_schedule,
                data.is_checked_in_now === "another_seat"
              )
            )
            setHasAnotherSchedule(data.has_another_schedule)
            setHasAnotherCheckIn(data.is_checked_in_now === "another_seat")
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 座席に予約が入っていない場合のモーダルのテキストを設定する
  const notReservedModalText = (
    hadAnotherSchedule: boolean,
    isCheckInNow: boolean
  ) => {
    switch (checkInType) {
      case "Seat":
        if (hadAnotherSchedule) {
          // return "他の席に予約が入っていますが、この座席にチェックインしてもよろしいですか？"
          return translations.anotherReserved
        } else if (isCheckInNow) {
          // return "現在他の座席にチェックイン中ですがこちらの座席を予約してもよろしいですか？"
          return translations.currentlyCheckedAnotherDesk
        } else {
          // return "この席にチェックインしますか？"
          return translations.wouldYouCheckIn
        }
        break
      case "MeetingRoom":
        return hadAnotherSchedule
          ? translations.reservationInAnotherSpace // "他の会議室に予約が入っていますが、同じメンバーでこの会議室にチェックインしてもよろしいですか？"
          : translations.wouldYouCheckInSpace // "この会議室にチェックインしますか？"
    }
  }

  // その場でチェックインを行う際の予約時間のプルダウンの内容を設定する
  const handleReservationTimePullDownSet = (
    nextReservationDate: string | null
  ) => {
    const roundedCurrentDate = roundUpMinutes(new Date(), 5) // 現在時刻を5分単位で切り上げ
    const roundedCurrentHour = roundedCurrentDate.getHours()

    // 現在時〜23までの配列
    let fromNowHours = [...Array(24 - roundedCurrentHour)].map(
      (_, i) => i + roundedCurrentHour
    )

    let nextReservationEndTime
    if (nextReservationDate) {
      const roundedNextReservationDate = roundDownMinutes(
        new Date(nextReservationDate),
        15
      ) // 次の予約を15分単位で切り捨て
      nextReservationEndTime = new Date(roundedNextReservationDate)
    }

    // 会議室を利用可能な終了時間を計算
    let availableEndTime
    if (availableTime && availableTime > 0) {
      availableEndTime = new Date(
        roundedCurrentDate.getTime() + availableTime * 60000
      )
    }

    // 次の予約終了時間または利用可能終了時間から最も近い時間を選択し設定
    if (nextReservationEndTime && availableEndTime) {
      const closestReservation =
        nextReservationEndTime.getTime() - roundedCurrentDate.getTime() <
        availableEndTime.getTime() - roundedCurrentDate.getTime()
          ? nextReservationEndTime
          : availableEndTime
      setNextReservation(closestReservation)
    } else if (nextReservationEndTime) {
      setNextReservation(nextReservationEndTime)
    } else if (availableEndTime) {
      setNextReservation(availableEndTime)
    }

    // 選択可能な終了時間をプルダウンに反映させるための処理
    if (nextReservationEndTime || availableEndTime) {
      const endTime = nextReservationEndTime || availableEndTime
      if (endTime) {
        const endHour = endTime.getHours()
        const endIndex = fromNowHours.indexOf(endHour)
        if (endIndex !== -1) {
          fromNowHours = fromNowHours.slice(0, endIndex + 1)
        }
      }
    }

    setReservationModalHours(fromNowHours)
  }

  // その場で予約を行う際に予約時間をstateに設定する
  const handleReservationHourChange = (e: SelectChangeEvent<number>) => {
    // 予約時間の「時」変更時、選択可能な「分」一覧を更新する
    // ※予約時間や選択肢は5分単位で丸める
    const now = roundUpMinutes(new Date(), 5)

    const isIncludedMinutesChoicesList = (minutes: number[]) => {
      return minutes.find((minute) => minute === reservationMinutes)
    }

    const selectedHour = Number(e.target.value)
    let selectedMinutes = reservationMinutes
    let minutesChoicesList = fiveIntervalMinutes // 選択可能な「分」一覧

    // 現在時と同じ「時」を選択した場合
    if (selectedHour === now.getHours()) {
      // 過去の「分」は選択肢から除外
      const nowMinutesIndex = minutesChoicesList.indexOf(now.getMinutes())
      minutesChoicesList = minutesChoicesList.slice(nowMinutesIndex)

      // 選択中の「分」が選択肢になければ、最初の選択肢を選んだ状態にする
      if (
        selectedMinutes !== -1 &&
        !isIncludedMinutesChoicesList(minutesChoicesList)
      ) {
        selectedMinutes = minutesChoicesList.slice()[0]
      }
    }

    // 次の予約時刻と同じ「時」を選択した場合
    if (nextReservation && selectedHour === nextReservation.getHours()) {
      // 次の予約時刻と被ってる「分」は選択肢から除外
      const nextReservationMinutes = roundDownMinutes(
        nextReservation,
        5
      ).getMinutes()
      const nextReservationMinutesIndex = minutesChoicesList.indexOf(
        nextReservationMinutes
      )

      minutesChoicesList = minutesChoicesList.slice(
        0,
        nextReservationMinutesIndex + 1
      )

      // 選択中の「分」が選択肢から外れたら、次の予約の直前の「分」を選んだ状態にする
      if (
        selectedMinutes !== -1 &&
        !isIncludedMinutesChoicesList(minutesChoicesList)
      ) {
        selectedMinutes = minutesChoicesList.slice(-1)[0]
      }
    }
    setReservationHour(selectedHour)
    setReservationMinutes(selectedMinutes)
    setReservationModalMinutes(minutesChoicesList)
  }

  const handleReservationMinutesChange = (e: SelectChangeEvent<number>) => {
    setReservationMinutes(Number(e.target.value))
  }

  // その場で予約・チェックインを実行する
  const handleInstantCheckIn = async () => {
    const today = new Date()
    const reservationTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      reservationHour,
      reservationMinutes
    )
    try {
      const { error } = await instantCheckInRequest({
        reservable_id: targetId,
        reservable_type: checkInType,
        end_time: reservationTime,
      })
      if (!error) {
        navigate(
          `/mobile/check-in/completed?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
        )
      } else {
        setCheckInError(true)
        setCheckInErrorMessage(translations.CheckInFailed)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    branchName,
    floorNumber,
    checkInType,
    checkInTarget,
    checkIn,
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    handleInstantCheckIn,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    setCheckInStateFromQueryString,
    hasAnotherSchedule,
    dataLoading,
    setDataLoading,
    hasAnotherCheckIn,
    checkInStatusJudgement,
  }
}
