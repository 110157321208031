import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Modal, Button } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleCheckIn } from "../../../../models/employee/useSchedule/checkIn"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { AlertError } from "../../../public/AlertError"
import { CheckInNotReservedModal } from "../CheckInNotReservedModal"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

interface Props {
  companyRelations: CompanyRelationsType
  checkInData: CheckInData
  onSuccess: () => void
}

// チェックインのメイン画面
export const CheckIn = ({
  companyRelations,
  checkInData,
  onSuccess,
}: Props) => {
  const navigate = useNavigate()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const {
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    setCheckInStateFromQueryString,
    hasAnotherSchedule,
    hasAnotherCheckIn,
    checkInFromHome,
    handleInstantCheckInFromHome,
  } = useScheduleCheckIn()

  const handleCheckIn = () => {
    if (checkInData.seatId !== null) {
      checkInFromHome({ seatId: checkInData.seatId })
    }
  }

  useEffect(() => {
    if (companyRelations) {
      setCheckInStateFromQueryString(companyRelations)
    }
  }, [companyRelations])

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h5">
            {checkInData.branchName}
          </CustomTypography>
          <CustomTypography variant="h5">
            {checkInData.floorNumber}F
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h4">
            {translations.Desk}：{checkInData.seatName}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography>{translations.YouCheckIn}</CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCheckIn}
          >
            <Typography fontWeight="bold">{translations.CheckIn}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate("/employee/layouts")}
          >
            <Typography fontWeight="bold">{translations.DeskStatus}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
          >
            <Typography fontWeight="bold">{translations.Home}</Typography>
          </Button>
        </Grid>
        <Modal
          open={notReserved}
          onClose={() => setNotReserved(false)}
          disableAutoFocus
        >
          <ModalBox>
            <CheckInNotReservedModal
              notReservedModalMessage={notReservedModalMessage}
              hours={reservationModalHours}
              minutes={reservationModalMinutes}
              reservationHour={reservationHour}
              reservationMinutes={reservationMinutes}
              handleReservationHourChange={handleReservationHourChange}
              handleReservationMinutesChange={handleReservationMinutesChange}
              setNotReserved={setNotReserved}
              checkInData={checkInData}
              handleInstantCheckInFromHome={handleInstantCheckInFromHome}
              onSuccess={onSuccess}
              hasAnotherSchedule={hasAnotherSchedule}
              hasAnotherCheckIn={hasAnotherCheckIn}
            />
          </ModalBox>
        </Modal>
        <AlertError
          isMessageOpen={checkInError}
          alertMessage={checkInErrorMessage}
          closeMessage={() => setCheckInError(false)}
        />
      </Grid>
    </Box>
  )
}
